//@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

/*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    /*---犬---*/
    #MainImg{
      .animalbg{
        position: absolute;
        top: 70px;
        right: 0;
        width: 483px;
        height: auto;
        z-index: 0;
        /*タイミング*/
        opacity: 0;
      }
    }


    /*---メインイメージ---*/
    #MainImg{
      width:100%;
      margin:0 auto;
      position:relative;
      overflow:hidden;
      height: 665px;
    }

    #MainImg #MainTitle{
        position: absolute;
        position: absolute;
        z-index:200;
        top:49%;
        transform: translateY(-50%);
        left: 0;
        right: -10px;
        margin:auto;
        text-align: center;
        font-size: 48px;
        letter-spacing: 0.2em;
        line-height: 1.5;
    }
    #MainImg .bxslider{
      width:100% !important;
      margin:0 auto;
      position:relative;
      }
    #MainImg .bxslider li:before{
      content:"";
      display:block;
      padding-top:51.4%;
      }



    .mtbg .bg{
      display:block;
      position:absolute;
      top:0;
      padding:0 !important;
      z-index:9995 !important;
      width:100% !important;
        height: 100%;
      background-size:cover !important;
      background-position:center center !important;
      background-size:100% !important;

    }

    /*---鳥イラスト---*/
    #MainImg .bxslider li .illust{
      position: absolute;
      z-index:9998;
    }

    /*---スライド1枚目イラスト---*/
    #MainImg .bxslider li:first-child .illust01{
        width: 62px;
        height: 60px;
        /*top:6.4vw;*/
        top:150px;
        left:calc(50% + 235px);
      -moz-animation: bird01 ease 6s infinite;
        -webkit-animation: bird01 ease 6s infinite;
        -ms-animation: bird01 ease 6s infinite;
        -o-animation: bird01 ease 6s infinite;
        animation: bird01 ease 6s infinite;
        opacity: 0;

    }

    @-webkit-keyframes bird01 {
     0% { transform:  translate3d(0,15px, 0px);}
     50% {transform:  translate3d(-15px,-15px, 0px); }
     100% { transform:  translate3d(0,15px, 0px); }
    }    
    @-o-keyframes bird01 {
     0% { transform:  translate3d(0,15px, 0px);}
     50% {transform:  translate3d(-15px,-15px, 0px); }
     100% { transform:  translate3d(0,15px, 0px); }
    }
    @-ms-keyframes bird01 {
     0% { transform:  translate3d(0,15px, 0px);}
     50% {transform:  translate3d(-15px,-15px, 0px); }
     100% { transform:  translate3d(0,15px, 0px); }
    }
    @keyframes bird01 {
     0% { transform:  translate3d(0,15px, 0px);}
     50% {transform:  translate3d(-15px,-15px, 0px); }
     100% { transform:  translate3d(0,15px, 0px); }
    }

    @-moz-keyframes bird01{
     0% { transform:  translate3d(0,15px, 0px);}
     50% {transform:  translate3d(-15px,-15px, 0px); }
     100% { transform:  translate3d(0,15px, 0px); }
    }

    #MainImg .bxslider li:first-child .illust02{
        width: 64px;
        height: 74px;
        position: absolute;
        top:310px;
        right:calc(50% + 310px);
        -moz-animation: bird02 ease 6s infinite;
        -webkit-animation: bird02 ease 6s infinite;
        -ms-animation: bird02 ease 6s infinite;
        -o-animation: bird02 ease 6s infinite;
        animation: bird02 ease 6s infinite;
        opacity: 0;
    }

    @-webkit-keyframes bird02 {
     0% { transform:  translate3d(-10px,-15px, 0px);}
     50% {transform:  translate3d(0,0, 0px); }
     100% { transform:  translate3d(-10px,-15px, 0px);}
    }
    @-o-keyframes bird02 {
     0% { transform:  translate3d(-10px,-15px, 0px);}
     50% {transform:  translate3d(0,0, 0px); }
     100% { transform:  translate3d(-10px,-15px, 0px);}
    }
    @-ms-keyframes bird02 {
     0% { transform:  translate3d(-10px,-15px, 0px);}
     50% {transform:  translate3d(0,0, 0px); }
     100% { transform:  translate3d(-10px,-15px, 0px);}
    }
    @keyframes bird02 {
     0% { transform:  translate3d(-10px,-15px, 0px);}
     50% {transform:  translate3d(0,0, 0px); }
     100% { transform:  translate3d(-10px,-15px, 0px);}
    }

    @-moz-keyframes bird02{
     0% { transform:  translate3d(-10px,-15px, 0px);}
     50% {transform:  translate3d(0,0, 0px); }
     100% { transform:  translate3d(-10px,-15px, 0px);}
    }


    .bx-wrapper img {
        width:100%;
    }

    /*---フェードイン---*/
    /*タイトル*/
    .fadeInTitle{
      opacity: 0;
      animation: fadeInTitle 1s ease 0.3s 1 normal;
      animation-fill-mode: forwards;
      animation-delay: 1s;
      animation-name: fadeInTitle;
    }

    @keyframes fadeInTitle { /*animation-nameで設定した値を書く*/

      0% {opacity: 0} /*アニメーション開始時は不透明度0%*/

      100% {opacity: 1} /*アニメーション終了時は不透明度100%*/

    }


    /*---スライドショー---*/
    .fadeInSlider{
      opacity: 0;
      animation: fadeInSlider 1s ease 0.3s 1 normal;
      animation-fill-mode: forwards;
      animation-delay: 4s;
      animation-name: fadeInSlider;
    }

    @keyframes fadeInSlider { /*animation-nameで設定した値を書く*/

      0% {opacity: 0} /*アニメーション開始時は不透明度0%*/

      100% {opacity: 1} /*アニメーション終了時は不透明度100%*/

    }

    /*---メインイメージ 横流れ---*/
    #MainImg .bxsliderBox{
      margin: 0 auto;
    }
    #MainImg  .bx-viewport{
    }

    #MainImg .bxslider{
      overflow: hidden;
      height: 100%!important;
    }

    #MainImg .mtbg .bg{
      /*複数のCss animation のバッティングにより効かない*/
      animation: slideAnime 15s;
      -ms-animation: slideAnime 15s;
      -webkit-animation: slideAnime 15s;
      animation-iteration-count: infinite;
      width: 100%;
    }

    .mtbg .bg{
      width: calc(100% + 100px)!important;
    }


    #MainImg .bxslider li:before{
      padding-top: 665px; /*backgroundのときのみ必要???*/
    }

    @keyframes slideAnime {
      0% {
        -ms-transform: -ms-translateX(0px);
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
      }
        100% {
        -ms-transform: -ms-translateX(0px);
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
      }
    }

    

    /*####################　ヘッダー　####################*/
    /*---バラの花---*/
    /*バラ固定 親*/
    #Header{
      position: relative;
      padding-top: 40px;

      height: auto;
      box-shadow: none;
    }

    /*ロゴ*/
    #Header h1{
      /*margin: 0 auto 30px;*/ /*common.cssへ*/
      position: static;
    }

    /*バラ固定 子*/
    #Header .r_illust {
        position: absolute;
        right: 0;
        top: -240px;
        z-index: 10;
        width: 265px;
        z-index: 100;
        display: block;

        /*タイミング*/
        opacity: 0;
    }
    #Header .l_illust {
        position: absolute;
        left: 0;
        top: -240px;
        z-index: 10;
        width: 265px;
        z-index: 100;
        display: block;

        /*タイミング*/
        opacity: 0;
    }

    /*ロゴ*/
    #Header h1 {
      margin: 0 auto 30px; 
      width: 258px;
    }

    #Header h1 a {
      display: block;
      height: 146px;
      width: 258px;
      background: url(../img/header/logo.png) no-repeat center center;
      background-size: 258px auto;
      text-indent: -9999px;
    }

    #Header .navBox {
      margin-bottom: 53px;
      position: static;
    }

    #Header .nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 0 auto; 

      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    #Header .nav01 {
      margin-bottom: 20px; 
      width: 706px;
    }

    #Header .nav01 .navBtn {
      margin-right: 20px;
      width: 222px; 
      font-size: 16px;
      font-weight: 700;
    }

    #Header .nav01 .navBtn:last-of-type {
      margin-right: 0;
    }

    #Header .nav01 .navBtn a {
      display: block; 
      border-radius: 6px;
      padding: 4px 0 4px 42px;
      color: #fff;
      letter-spacing: 0.2em;
    }

    #Header .nav01 .navBtn01 a {
      background: url(../img/header/icon_nav01.png) no-repeat left 16px center, url(../img/header/arrow01.png) no-repeat right 17px center;
      background-color: #89c800; 
      background-size: 19px auto, 9px auto;
    }

    #Header .nav01 .navBtn02 a {
      background: url(../img/header/icon_nav02.png) no-repeat left 16px center, url(../img/header/arrow01.png) no-repeat right 17px center;
      background-color: #ff8201; 
      background-size: 18px auto, 9px auto;
    }

    #Header .nav01 .navBtn03 a {
      background: url(../img/header/icon_nav03.png) no-repeat left 16px center, url(../img/header/arrow01.png) no-repeat right 17px center;
      background-color: #2c6e34; 
      background-size: 22px auto, 9px auto;
      padding-left: 45px;
    }

    #Header .nav02 {
      position: relative; 
      width: 824px;
    }

    #Header .nav02 a {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      padding-bottom: 8px;
      display: inline-block;
    }

    #Header .nav02 a:hover {
      border-bottom: 2px solid #89c800;
      text-decoration: none;
    }

    #Header .nav02 span {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      padding-bottom: 8px;
      display: inline-block;
    }
    #Header .nav02 span:hover {
      border-bottom: 2px solid #89c800;
      text-decoration: none;
    }

    #Header .nav02 .nav_subMenu {
      position: relative;
    }

    #Header .nav02 .nav_subMenu_wrap {
      position: absolute;
      top: 25px;
      right: -25px;
      visibility: hidden;
      padding-top: 27px;
      width: 200px; 
      opacity: 0;
      -webkit-transition: all .3s;
              transition: all .3s;
    }

    #Header .nav02 .nav_subMenu:hover .nav_subMenu_wrap {
      visibility: visible;
      opacity: 1;
    }

    #Header .nav02 .nav_subMenu_cont {
      position: relative; 
      //background-color: #2c6e34;
      background-color: #fff;
    }

    #Header .nav02 .nav_subMenu_cont::after {
      content: "";
      position: absolute;
      top: -8px;
      left: 50%;
      display: block;
      width: 11px;
      height: 9px;
      background: url(../img/header/bg_img01.png) no-repeat top center;
      background-size: 11px auto;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }

    #Header .nav02 .nav_subMenu_cont li {
      background-color: #2c6e34;
      &:hover{
        opacity: 0.7;
      }
      &:last-of-type{
        a{
          border-bottom: none;
        }
      }
      a {
        display: block;
        border-bottom: 1px solid #fff;
        padding: 5px 50px 4px 20px;
        background: url(../img/contents/arrow01.png) no-repeat right 20px center;
        background-size: 9px auto;
        color: #fff;
        margin-right: 0;
      }
    }



    /*ヘッダー追従時*/
  &.fixed #Header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    padding-top: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
  }
  &.fixed #Header h1{
    position: absolute;
    top: 20px;
    left: 29px;
    margin-bottom: 0;
  }
  &.fixed #Header h1 a{
    background: url(../img/header/logo_scroll.png) no-repeat center center;
    background-size: 278px auto;
    width: 278px;
    height: 50px;
  }
  &.fixed #Header .l_illust{
    display: none;
  }
  &.fixed #Header .r_illust{
    display: none;
  }
  &.fixed #Header .navBox{
    position: absolute;
    top: 15px;
    right: 30px;
  }
  &.fixed #Header .nav{
    justify-content: flex-end;
  }
  &.fixed #Header .nav01{
    width: 100%;
    margin-bottom: 5px;
  }
  &.fixed #Header .nav01 .navBtn{
    width: auto;
    margin-right: 14px;
  }
  &.fixed #Header .nav01 .navBtn:last-of-type{
    margin-right: 0;
  }
  &.fixed #Header .nav01 .navBtn a{
    font-size: 14px;
    padding: 2px 41px 2px 41px;
    letter-spacing: 0.02em;
  }
  &.fixed #Header .nav01 .navBtn03 a{
    padding: 2px 46px 2px 46px;
  }
  &.fixed #Header .nav02{
    width: 100%;
    a{
      font-size: 14px;
      display: inline-block;
      &.current-nav{
        border-bottom: 2px solid #89c800;
        padding-bottom: 7px;
      }
      &:hover{
        padding-bottom: 7px;
      }
    }
    span{
      font-size: 14px;
      display: inline-block;
      &:hover{
        padding-bottom: 7px;
      }
    }
    .nav_subMenu{
      .nav_subMenu_cont{
        li{
          a{
            &:hover{
              padding-bottom: 4px;
            }
          }
        }
      }
    }
  }

  &.fixed #Container{
    margin-top: 360px;
  }



    #ContBox01 .bgPost{
      background: url(../img/contents/bg_img01.png) repeat center top;
      //background-size: 1280px auto;
      background-size: 100px auto;
      padding: 40px 0;
    }

    #ContBox01 table{
      height: 100%;
      margin: 0 auto;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
    }
    #ContBox01 th{
      background-color: #ff8201;
      font-family: "Hannari", serif;
      font-weight: 400;
      font-size: 24px;
      color: #fff;
      width: 215px;
      height: 100%;
      vertical-align: middle;
      text-align: center;
      padding-left: 4px;
      letter-spacing: 0.1em;
    }
    #ContBox01 td {
      background-color: #fff;
      width: 785px;
      padding: 20px 30px;
    }
    #ContBox01 .post .post_item_cont::after{
      content: "";
      clear: both;
      display: block;
    }
    #ContBox01 .post .post_item_cont .time{
      font-size: 14px;
      color: #2c6e34;
      float: left;
      line-height: 32px;
        display: inline-block;
        margin-right: 15px;
    }
    #ContBox01 .post .post_item_cont .title{
      float: left;
      width: calc(100% - 100px);
      display: inline-block;
      letter-spacing: 0.04em;
      font-weight: 500;
      color: #000000;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    #ContBox01 .bgFlower{
      background: url(../img/contents/bg_img08.png) repeat center top;
      background-size: cover;
      height: 186px;
      display: flex;
      align-items: center;
      justify-content: center;
      .f_title{
        font-size: 18px;
        font-weight: 400;
        font-family: 'Noto Sans', sans-serif;
        color: #fff;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        letter-spacing: 0.54em;
        margin-left: 12px;
      }
    }

    #ContBox02{
      padding-top: 27px;
      background: url(../img/contents/bg_img02.png) repeat center top;
      background-size: 1280px auto;
      background-color: #f6fbe8;
      overflow: hidden;
      position: relative;
      figure{
        width: 776px;
        margin: 8px auto 50px;
      }
    }
    #ContBox02 .h_title{
      margin-bottom: 40px;
    }
    #ContBox02 #ContSubBox02{
      background-color: #fff;
      padding: 50px 30px 223px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
      position: relative;
    }
    #ContBox02 #ContSubBox02 h3{
      font-size: 24px;
      color: #2c6e34;
      line-height: 1.5;
      margin-bottom: 32px;
      font-family: "Hannari", serif;
      font-weight: 400;
      letter-spacing: 0.06em;
      margin-left: 18px;
    }
    #ContBox02 #ContSubBox02 p{
      text-align: center;
      font-weight: 500;
      position: relative;
      z-index: 5;
    }
    #ContBox02 #ContSubBox02 .frameBtnOrg{
      width: 760px;
      margin: 54px auto 0;
      padding: 11px 0 10px;
      position: relative;
      z-index: 5;
    }
    #ContBox02 #ContSubBox02 .leaf{
      position: absolute;
    }
    #ContBox02 #ContSubBox02 .leaf-l{
      background: url(../img/contents/bg_img03-l.png) no-repeat center center;
      background-size: 286px auto;
      width: 286px;
      height: 273px;
      bottom: 245px;
      left: -65px;
    }
    #ContBox02 #ContSubBox02 .leaf-r{
      background: url(../img/contents/bg_img03-r.png) no-repeat center center;
      background-size: 287px auto;
      width: 287px;
      height: 220px;
      top: -140px;
      right: -120px;
    }
    #ContBox02 .bgGrass{
      width: 100%;
      height: 243px;
      background: url(../img/contents/bg_img07.png) no-repeat center top;
      background-size: cover;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }


    #ContBox03{
      padding: 50px 0 78px 0;
      border-bottom: 1px solid #dfdfdf;
    }
    #ContBox03 #ContSubBox03{
      background: url(../img/contents/bg_img04.png) no-repeat left top 14px;
      background-size: 895px auto;
    }
    #ContBox03 #ContSubBox03 .modal{
      width: 440px;
      margin: 0 0 81px auto;
      padding-top: 115px;
    }

    #ContBox03 #ContSubBox03 .modal_list{
      text-align: left;
      width: 211px;
      height: 122px;
      float: left;
      margin-right: 18px;
      margin-bottom: 18px;
      background-color: #fff;
      position: relative;
      b{
        &:hover{
          opacity: 0.7;
          cursor: pointer;
        }

      }
      img{
      }
    }
    #ContBox03 #ContSubBox03 .modal_list:nth-of-type(2n){
      margin-right: 0;
    }
    #ContBox03 #ContSubBox03 .modal_list07,
    #ContBox03 #ContSubBox03 .modal_list08{
      margin-bottom: 0;
    }
    #ContBox03 #ContSubBox03 .modal_title{
      font-size: 18px;
      font-weight: 700;
      color: #2c6e34;
      background: url(../img/contents/icon_img01.png) no-repeat right 10px center;
      background-size: 26px auto;
      background-color: #fff;
      opacity: 0.9;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-left: 8px;
    }
    #ContBox03 #ContSubBox03 .linkBtn{
      font-size: 18px;
    }

    #ContBox04{
      padding: 50px 0 80px;
    }
    #ContBox04 .h_title{
      margin-bottom: 50px;
    }
    #ContBox04 #ContSubBox04 .grave{
      margin-bottom: 80px;
      font-family: "Hannari", serif;
      font-weight: 400;
      display: flex;
      justify-content: center;
    }
    #ContBox04 #ContSubBox04 .grave_list{
      display: inline-block;
      //float: left;
      margin-right: 54px;
    }
    #ContBox04 #ContSubBox04 .grave_list:last-of-type{
      margin-right: 0;
    }
    #ContBox04 #ContSubBox04 .grave_list .imgBox{
      display: block;
      width: 294px;
      height: 294px;
      margin-bottom: 31px;
      position: relative;
      &:hover{
        opacity: 0.7;
        pointer: cursor;
      }
    }
    /*#ContBox04 #ContSubBox04 .grave_list img{
      border-radius: 50%;
      width: 294px;
      height: 294px;
      border: 4px solid #8fd001;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15), 9px 11px 1px #f6f6f6;
    }*/
    #ContBox04 #ContSubBox04 .grave_list .imgBoxCont{
      border-radius: 50%;
      width: 294px;
      height: 294px;
      border: 4px solid #8fd001;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15), 9px 11px 1px #f6f6f6;
    }
    #ContBox04 #ContSubBox04 .grave_list .linkIcon{
      position: absolute;
      bottom: 0;
      right: 21px;
    }
    #ContBox04 #ContSubBox04 .grave_list .linkIcon b{
      display: block;
      width: 60px;
      height: 60px;
      background: url(../img/contents/arrow02.png) no-repeat center center;
      background-size: 18px auto;
      background-color: #8fd001;
      border-radius: 50%;
      position: relative;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
    }
    #ContBox04 #ContSubBox04 .grave_list .linkIcon b::after{
      content: "";
      display: block;
      width: 54px;
      height: 54px;
      border: 1px solid #fff;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
    }
    #ContBox04 #ContSubBox04 .grave_list .grave_rubi{
      color: #888;
      font-size: 14px;
      display: block;
      line-height: 1.8;
      margin-left: 14px;
      letter-spacing: 0.10em;
    }
    #ContBox04 #ContSubBox04 .grave_list .grave_title{
      color: #2c6e34;
      font-size: 24px;
      text-align: center;
      line-height: 1.5;
      letter-spacing: 0.2em;
      margin-left: 15px;
    }
    #ContBox04 #ContSubBox04 .linkBtn{
      font-size: 18px;
    }

    #ContBox05{
      background: url(../img/contents/bg_img05.png) repeat center top;
      //background-size: 1280px auto;
      background-size: 100px auto;
      padding: 50px 0 100px;
    }
    #ContBox05 .h_title{
      margin-bottom: 50px;
    }
    #ContBox05 #ContSubBox05{
      max-width: 1280px;
      margin: 0 auto;
    }
    #ContBox05 #ContSubBox05 .flow{
      margin-bottom: 75px;
    }
    #ContBox05 #ContSubBox05 .flow_list{
      position: relative;
    }
    #ContBox05 #ContSubBox05 .flow_list01{
      background: url(../img/contents/bg_img05_01.png) no-repeat calc(50% + 50px) bottom;
      background-size: 541px auto;
      padding: 125px 0 155px;
    }
    #ContBox05 #ContSubBox05 .flow_list02{
      background: url(../img/contents/bg_img05_02.png) no-repeat calc(50% - 220px) top, url(../img/contents/bg_img05_03.png) no-repeat calc(50% + 50px) bottom;
      background-size: 1px auto, 541px auto;
      padding: 175px 0 155px;
    }
    #ContBox05 #ContSubBox05 .flow_list03{
      background: url(../img/contents/bg_img05_02.png) no-repeat calc(50% + 320px) top, url(../img/contents/bg_img05_01.png) no-repeat calc(50% + 50px) bottom;
      background-size: 1px auto, 541px auto;
      padding: 175px 0 155px;
    }
    #ContBox05 #ContSubBox05 .flow_list04{
      background: url(../img/contents/bg_img05_02.png) no-repeat calc(50% - 220px) top, url(../img/contents/bg_img05_03.png) no-repeat calc(50% + 50px) bottom;
      background-size: 1px auto, 541px auto;
      padding: 123px 0 110px;
    }
    #ContBox05 #ContSubBox05 .flow_list05{
      background: url(../img/contents/bg_img05_02.png) no-repeat calc(50% + 320px) top;
      background-size: 1px auto;
      padding: 185px 0 130px;
    }
    #ContBox05 #ContSubBox05 .flow_list .imgBox{
      width: 50%;
      height: 395px;
      position: absolute;
    }
    #ContBox05 #ContSubBox05 .flow_list01 .imgBox{
      background: url(../img/contents/flow_img01.png) no-repeat right center;
      background-size: cover;
    }
    #ContBox05 #ContSubBox05 .flow_list02 .imgBox{
      background: url(../img/contents/flow_img02.png) no-repeat left center;
      background-size: cover;
    }
    #ContBox05 #ContSubBox05 .flow_list03 .imgBox{
      background: url(../img/contents/flow_img03.png) no-repeat right center;
      background-size: cover;
    }
    #ContBox05 #ContSubBox05 .flow_list04 .imgBox{
      background: url(../img/contents/flow_img04.png) no-repeat left center;
      background-size: cover;
    }
    #ContBox05 #ContSubBox05 .flow_list05 .imgBox{
      background: url(../img/contents/flow_img05.png) no-repeat right center;
      background-size: cover;
    }
    #ContBox05 #ContSubBox05 .flow_list:nth-of-type(odd) .imgBox{
      box-shadow: 10px 10px 0 #e6e6e6;
      top: 50px;
      left: 0;
    }
    #ContBox05 #ContSubBox05 .flow_list:nth-of-type(even) .imgBox{
      box-shadow: -10px 10px 0 #e6e6e6;
      top: 50px;
      right: 0;
    }
    #ContBox05 #ContSubBox05 .flow_list:nth-of-type(1) .imgBox{
      top: 0;
    }
    #ContBox05 #ContSubBox05 .flow_list dl{
      width: 50%;
    }
    #ContBox05 #ContSubBox05 .flow_list:nth-of-type(odd) dl{
      margin: 0 0 0 auto;
      padding-left: 140px;
    }
    #ContBox05 #ContSubBox05 .flow_list:nth-of-type(even) dl{
      margin: 0 auto 0 -40px;
      padding-left: 140px;
    }
    #ContBox05 #ContSubBox05 .flow_list dl dt{
      font-size: 32px;
      font-weight: 500;
      color: #2c6e34;
      text-align: left;
      line-height: 1.5;
      letter-spacing: 0.1em;
      position: relative;
    }
    #ContBox05 #ContSubBox05 .flow_list dl dt .stepIcon{
      position: absolute;
      top: -20px;
      left: -100px;
      background-color: #8fd001;
      border-radius: 50%;
      width: 81px;
      height: 81px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      padding-top: 30px;
      text-align: center;
      letter-spacing: 0em;
    }
    #ContBox05 #ContSubBox05 .flow_list dl dt .stepIcon b{
      width: 78px;
      height: 78px;
      display: block;
      position: relative;
    }
    #ContBox05 #ContSubBox05 .flow_list dl dt .stepIcon b::after{
      content: "";
      display: block;
      width: 73px;
      height: 73px;
      border-radius: 50%;
      border: 1px solid #fff;
      position: absolute;
      top: -51px;
        left: 3px;
    }
    #ContBox05 #ContSubBox05 .flow_list dl dd{
      font-size: 16px;
      font-weight: 500;
      text-align: justify;
    }

    #ContBox06{
      padding: 45px 0 80px;
    }
    #ContBox06 .h_title{
      margin-bottom: 50px;
    }
    #ContBox06 #ContSubBox06 .map{
      margin-bottom: 40px;
    }
    #ContBox06 #ContSubBox06 .map iframe{
      width: 100%;
      height: 320px;
    }
    #ContBox06 #ContSubBox06 .map p{
      text-align: right;
    }
    #ContBox06 #ContSubBox06 .map a{
      font-size: 14px;
      font-weight: 500;
      color: #2c6e34;
      text-align: right;
      background: url(../img/contents/icon_img02.png) no-repeat left center;
      background-size: 18px auto;
      padding-left: 25px;
      padding-top: 3px;
      padding-bottom: 3px;
    }
    #ContBox06 #ContSubBox06 .access{
      display: flex;
      margin-bottom: 55px;
    }
    #ContBox06 #ContSubBox06 .access_Box{
      width: 50%;
    }
    #ContBox06 #ContSubBox06 .access_Box01{
      margin-bottom: 25px;
      padding-right: 30px;
      .frameBtnGrn{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    #ContBox06 #ContSubBox06 .access_Box01 .access_list{
      text-align: left;
    }
    #ContBox06 #ContSubBox06 .access_Box01 .access_list01{
      //margin-bottom: 30px;
    }
    #ContBox06 #ContSubBox06 .access_Box01 .access_list01 .grey{
      color: #888;
      font-size: 14px;
    }
    #ContBox06 #ContSubBox06 .access_Box01 dt,
    #ContBox06 #ContSubBox06 .access_Box02 p{
      font-size: 24px;
      font-weight: 700;
      color: #2c6e34;
      letter-spacing: 0.1em;
    }
    #ContBox06 #ContSubBox06 .access_Box01 .access_list02{
      position: relative;
      padding-top: 32px;
      .access_bus_img{
        position: absolute;
        top: -8px;
        right: -11px;
        width: 320px;
        height: auto;
      }
      table{
        margin-top: 58px;
        margin-bottom: 40px;
        width: 100%;
        tr{
          border-bottom: 1px solid #fff;
          th{
            background-color: #2c6e34;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            padding: 7px 0;
            border-right: 1px solid #fff;
            &:last-of-type{
              border-right: none;
            }
          }
          td{
            background-color: #f6f6f6;
            font-size: 24px;
            font-weight: 700;
            color: #000000;
            text-align: center;
            vertical-align: middle;
            width: 120px;
            border-right: 1px solid #fff;
            line-height: 1.5;
            padding: 11px 0;
            &:last-of-type{
              border-right: none;
            }
            &.item{
              background-color: #f1fbd6;
              font-size: 16px;
              color: #2c6e34;
              width: 230px;
            }
          }
        }
      }
    }
    #ContBox06 #ContSubBox06 .access_Box01 dt{
      padding-left: 40px;
    }
    #ContBox06 #ContSubBox06 .access_car{
      background: url(../img/contents/icon_img03.png) no-repeat left center;
      background-size: 32px auto;
    }
    #ContBox06 #ContSubBox06 .access_bus{
      background: url(../img/contents/icon_img04.png) no-repeat left top 6px;
      background-size: 28px auto;
    }
    #ContBox06 #ContSubBox06 .access_Box01 dd{
      line-height: 2.0;
      font-weight: 500;
      letter-spacing: 0.1em;
    }
    #ContBox06 #ContSubBox06 .access_Box01 dd .bar{
      display: block;
        padding-left: 15px;
        background: url(../img/contents/icon_img05.png) no-repeat left center;
        background-size: auto 1px;
    }
    #ContBox06 #ContSubBox06 .access_Box01 .dot{
      padding-left: 15px;
      position: relative;
    }
    #ContBox06 #ContSubBox06 .access_Box01 .dot::after{
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #8fd001;
      position: absolute;
      top: 12px;
      left: 0;
    }
    #ContBox06 #ContSubBox06 .access_Box01 .frameBtnGrn{
      width: 470px;
      line-height: 1.8;
      margin-top: 17px;
      letter-spacing: 0.04em;
    }
    #ContBox06 #ContSubBox06 .access_Box01 .frameBtnGrn b{
      font-size: 28px;
      font-weight: bold;
      font-family: 'Cinzel', serif;
      margin-left: 10px;
    }
    #ContBox06 #ContSubBox06 .access_Box02 p{
      text-align: center;
      margin-bottom: 39px;
    }
    #ContBox07 #ContSubBox07 .bnr01{
      background: url(../img/contents/bg_img06.png) repeat center top;
      background-size: 100px auto;
      padding: 50px 0;
    }
    #ContBox07 #ContSubBox07 .bnr01 .innerBasic{
      display: flex;
      justify-content: space-between;
    }
    #ContBox07 #ContSubBox07 .bnr01 img{
      width: 490px;
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.06);
    }
    #ContBox07 #ContSubBox07 .bnr02{
      padding: 30px 0;
    }
    #ContBox07 #ContSubBox07 .bnr02 .innerBasic{
      padding: 0 110px;
      display: flex;
      justify-content: space-between;
    }
    #ContBox07 #ContSubBox07 .bnr02 img{
      width: 240px;
    }
    #ContBox07 #ContSubBox07 .bnr02 a:nth-of-type(3){
      border-radius: 5px;
      border: 1px solid #4a4849;
      img{
        transform: scale(0.90);
      }
    }
    #ContBox07 #ContSubBox07 .bnr a{
      display: block;
      &:hover{
        opacity: 0.7;
      }
    }
  }

/*
霊園概要
-------------------------------------*/
#PageAbout.pageIndex {
  /*---メインイメージ---*/
  #MainImg{
    #MainImgInner{
      //background: url(../img/contents/about/main_visu_about.png) no-repeat center center;
      //background-size: cover;

      height: auto;

      //video
      .hLarge{
        height: auto;
        display: flex;
      }

      .video{
        width: 100%;
        height: 640px;
        object-fit: cover;
      }
    }
  }
  .lowerTitle{
    margin-bottom: 50px;
    position: relative;
    &::after{
      content: "";
      display: block;
      background: url(../img/contents/about/bar01.png) no-repeat center center;
      background-size: 1px auto;
      width: 1px;
      height: 20px;
      position: absolute;
      top: 78px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .navCont{
    margin-bottom: 82px;
  }
  .contBox01{
    padding: 50px 0 0;
    .contSubBox{
      border-bottom: 1px solid #dfdfdf;
      margin-bottom: 80px;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    .pointCont{
      padding: 28px 0 40px;
      .pointItem{
        position: relative;
        margin-bottom: 40px;
        &::after{
          content: "";
          clear: both;
          display: block;
        }
        &:nth-of-type(odd){
          .imgBox{
            float: right;
          }
        }
        .imgBox{
          width: 680px;
        }
      }
    }
    .setRight{
      font-size: 16px;
      overflow: hidden;/*float解除*/
      display: flex;
      flex-direction: row-reverse;
    }
    .contSubBox02{
      padding-bottom: 80px;
      .pointCont{
        padding-bottom: 5px;
        .item_freebus{
          margin-bottom: 22px;
        }
      }
      .diagBoxWrap{
        display: flex;
        flex-wrap: wrap;
        .diagBox{
          width: 50%;
          text-align: left;
          padding-left: 55px;
          margin-top: 20px;
          &:nth-of-type(odd){
            //float: left;
          }
          &:nth-of-type(even){
            //float: right;
          }
          &:nth-of-type(1), &:nth-of-type(2){
            margin-bottom: 17px;
          }
          &.train{
            background: url(../img/contents/about/icon_train02.png) no-repeat left 4px top 16px;
            background-size: 40px auto;
          }
          &.walk{
            background: url(../img/contents/about/icon_walk02.png) no-repeat left 11px top 4px;
            background-size: 38px auto;
          }
          &.bus{
            background: url(../img/contents/about/icon_bus02.png) no-repeat left 4px top 16px;
            background-size: 40px auto;
          }
          &.car{
            background: url(../img/contents/about/icon_car02.png) no-repeat left 11px top 18px;
            background-size: 40px auto;
          }

          dt{
            font-size: 18px;
            font-weight: 700;
            color: #2c6e34;
            border-bottom: 1px solid #dfdfdf;
            line-height: 1.7;
          }
          dd{
            font-size: 12px;
            line-height: 1.2;
            margin-top: 7px;
            span{
              font-size: 30px;
              font-weight: 700;
              color: #89c800;
              display: block;
              float: right;
              line-height: 1.0;
              margin-top: -16px;
              b{
                font-size: 18px;
                font-weight: 500;
                color: #000;
              }
            }
          }
        }
      }
      .imgBox{
        &.item_freeBusImage{
          width: 706px;
        }
      }
    }
    .contSubBox03{
      .img{
        margin-left: 25px;
        img{
          width: 110px;
        }
        .anshinLink{
          display: block;
          &:hover{
            opacity: 0.7;
          }
        }
      }
      .textBox{
        a{
          padding-right: 20px;
          background: url(../img/contents/icon_blank.png) no-repeat right center;
          background-size: 13px auto;
        }
      }
      .pointItem{
        &:nth-of-type(2){
          .imgBox{
            border: 1px solid #dfdfdf;
          }
        }
      }
    }
    .contSubBox04{
      .pointItem{
        &:nth-of-type(1){
          .img{
            &::after{
              content: "貸出用車椅子";
              display: block;
              color: #8f8f8f;
              font-size: 12px;
            }
            img{
              width: 90px;
            }
          }
        }
        &:nth-of-type(2){
          .img{
            &::after{
              content: "園内移動用カート";
              display: block;
              color: #8f8f8f;
              font-size: 12px;
            }
            img{
              width: 117px;
            }
          }
        }
        .img{
          margin-left: 25px;
        }
      }
    }
  }
  .contBox02{
    padding: 51px 0 0;
    .contSubBox01{
      .map{
        margin-bottom: 43px;
        iframe{
          width: 100%;
          height: 325px;
        }
        .mapText{
          text-align: left;
          p{
            display: inline-block;
            &:nth-of-type(2){
              float: right;
            }
            a{
              font-size: 14px;
              font-weight: 500;
              color: #2c6e34;
              text-align: right;
              background: url(../img/contents/icon_img02.png) no-repeat left center;
              background-size: 19px auto;
              padding-left: 25px;
              padding-top: 3px;
              padding-bottom: 3px;
            }
          }
        }
      }

      .access{
        display: flex;
        margin-bottom: 65px;
      }
      .access_Box01{
        width: 46.5%;
      }
      .access_Box02{
        width: calc(100% - 46.5%);
      }
      .access_Box01 li{
        margin-bottom: 12px;
      }
      .access_Box01 .access_list{
        text-align: left;
        margin-bottom: 15px;
        font-size: 16px;
      }
      .access_Box01 .access_list01{
        margin-bottom: 8px;
      }
      .access_Box01 .access_list01 .grey{
        color: #888;
        font-size: 14px;
      }
      .access_Box01 dt,
      .access_Box02 p{
        font-size: 24px;
        font-weight: 700;
        color: #2c6e34;
        letter-spacing: 0.1em;
      }
      .access_Box01 dt{
        padding-left: 40px;
      }
      .access_car{
        background: url(../img/contents/about/icon_car.png) no-repeat left center;
        background-size: 32px auto;
      }
      .access_train{
        background: url(../img/contents/about/icon_train.png) no-repeat left center;
        background-size: 28px auto;
      }
      .access_walk{
        background: url(../img/contents/about/icon_walk.png) no-repeat left center;
        background-size: 35px auto;
      }
      .access_bus{
        background: url(../img/contents/about/icon_bus.png) no-repeat left center;
        background-size: 29px auto;
      }
      .access_freebus{
        background: url(../img/contents/about/icon_freebus.png) no-repeat left center;
        background-size: 32px auto;
      }

      .access_Box01 dd{
        line-height: 1.9;
        font-weight: 500;
        a{
          background: url(../img/contents/icon_blank.png) no-repeat right center;
          background-size: 15px auto;
          padding-right: 20px;
        }
      }
      .access_Box01 dd .bar{
        display: block;
        padding-left: 15px;
        background: url(../img/contents/icon_img05.png) no-repeat left center;
        background-size: auto 1px;
      }
      .access_Box01 .dot{
        padding-left: 15px;
        position: relative;
      }
      .access_Box01 .dot::after{
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #8fd001;
        position: absolute;
        top: 12px;
        left: 0;
      }
      .access_Box01 .frameBtnGrn{
        width: 405px;
        line-height: 1.0;
        margin-top: 23px;
        letter-spacing: 0.25em;
        font-size: 16px;
        padding: 15px 0 10px;
        b{
          font-family: 'Cinzel', serif;
          font-size: 40px;
          font-weight: bold;
          letter-spacing: 0.0em;
        }
      }
      .access_Box01 .access_list03{
        margin-bottom: 30px;
        .movie{
          width:  340px;
          height: 190px;
          margin-top: 15px;
          iframe{
            width: 100%;
            height: 100%;
          }
        }
      }
      .access_Box01 .access_list04{
        margin-bottom: 25px;
        .diagBox{
          width: 404px;
          margin: 23px 0 0;
        }
      }
      .access_Box01 .access_list05{
        position: relative;
        padding-top: 32px;
        padding-right: 60px;
        .access_bus_img{
          position: absolute;
          top: 5px;
          right: 50px;
          width: 270px;
          height: auto;
        }
        table{
          margin-top: 50px;
          margin-bottom: 40px;
          width: 100%;
          tr{
            border-bottom: 1px solid #fff;
            th{
              background-color: #2c6e34;
              color: #fff;
              font-size: 18px;
              font-weight: 700;
              text-align: center;
              padding: 7px 0;
              border-right: 1px solid #fff;
              &:last-of-type{
                border-right: none;
              }
            }
            td{
              background-color: #f6f6f6;
              font-size: 24px;
              font-weight: 700;
              color: #000000;
              text-align: center;
              vertical-align: middle;
              width: 120px;
              border-right: 1px solid #fff;
              line-height: 1.5;
              padding: 11px 0;
              &:last-of-type{
                border-right: none;
              }
              &.item{
                background-color: #f1fbd6;
                font-size: 16px;
                color: #2c6e34;
                width: 230px;
              }
            }
          }
        }
      }

      .access_Box02 p{
        text-align: center;
        margin-bottom: 35px;
      }
      .access_Box02 .diagBox{
        &:nth-of-type(1){
          border: 1px solid #dfdfdf;
          padding: 40px 10px 53px;
          margin-top: 8px;
          margin-bottom: 48px;
        }
      }


    }
  }
}

//IE対応
@media all and (-ms-high-contrast: none) {
  #PageAbout.pageIndex #MainImg #MainImgInner .video{
    height: auto;
    object-fit: fill;
  }
}




/*
各種墓所のご案内
-------------------------------------*/
#PageGuide.pageIndex {
  /*---メインイメージ---*/
  #MainImg{
    #MainImgInner{
      background: url(../img/contents/guide/main_visu_guide.png) no-repeat center center;
      background-size: cover;
      .linkBtn{
        width: 215px;
        position: absolute;
        right: 20px;
        bottom: 20px;
        padding: 0;
        a{
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          text-decoration: none;
          padding: 7px 0 7px 19px;
          letter-spacing: 0.11em;
          display: block;
          &:hover{
            opacity: 0.7;
          }
        }
      }
    }
  }
  .contBox01{
    background: url(../img/contents/guide/bg_01.png) no-repeat center center;
    background-size: cover;
    height: 303px;
    .contSubBox01{
      padding-top: 50px;
      .contWrap{
        p{
          font-size: 20px;
          font-weight: 500;
          color: #fff;
          text-align: center;
          letter-spacing: 0.08em;
          line-height: 1.8;
          margin-bottom: 30px;
          &:last-of-type{
            margin-bottom: 0;
          }
          span{
            font-size: 16px;
          }
        }
        ul{
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          li{
            background-color: #fff;
            color: #2c6e34;
            font-weight: 700;
            height: 90px;
            vertical-align: middle;
            padding: 0 10px;
            &:nth-of-type(1), &:nth-of-type(3){
              font-size: 24px;
            }
            &:nth-of-type(1), &:nth-of-type(2){
              position: relative;
              &::after{
                content: "";
                display: block;
                width: 26px;
                height: 26px;
                background: url(../img/contents/guide/icon_plus.png) no-repeat center center;
                background-size: 26px auto;
                position: absolute;
                top: 50%;
                right: -54px;
                transform: translateY(-50%);
              }
            }
            &:nth-of-type(1){
              padding: 22px 48px;
            }
            &:nth-of-type(2){
              font-size: 32px;
              line-height: 0.9;
              padding: 18px 120px;
            }
            &:nth-of-type(3){
              padding: 22px 73px;
            }
            .grey{
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .contBox02{
    margin-bottom: 70px;
    .guide {
      .guide_list{
        padding: 65px 0 50px;
        border-bottom: 1px solid #dfdfdf;
        .titleBox{
          text-align: left;
          margin-bottom: 20px;
          .title{
            font-size: 32px;
            color: #2c6e34;
            display: inline-block;
            vertical-align: middle;
            margin-right: 12px;
            letter-spacing: 0.08em;
          }
          .label{
            font-size: 18px;
            border: 1px solid #89c800;
            color: #89c800;
            display: inline-block;
            vertical-align: middle;
            padding: 0 8px;
            line-height: 1.8;
            letter-spacing: 0.08em;
          }
        }
        .contentsBox{
          .leadTxt{
            font-size: 16px;
            font-weight: 500;
            width: 480px;
            letter-spacing: 0.09em;
          }
          .infoBox{
            margin-right: 40px;
            margin-top: 10px;
            float: left;
            table{
              margin-bottom: 30px;
              width: 480px;
              tr{
                border-bottom: 1px solid #fff;
                &:last-of-type{
                  border-bottom: none;
                }
                th{
                  background-color: #f1fbd5;
                  color: #2c6e34;
                  text-align: center;
                  vertical-align: middle;
                  line-height: 1.5;
                  padding: 8px 0;
                  &:nth-of-type(1){
                    width: 20%;
                  }
                  &:nth-of-type(2){
                    width: 54%;
                  }
                }
                td{
                  background-color: #f8f8f8;
                  text-align: center;
                  vertical-align: middle;
                  letter-spacing: 0.15em;
                  line-height: 1.5;
                  padding: 8px 0;
                }
              }
              .borderR{
                border-right: 1px solid #fff;
              }
            }
            .comment{
              display: flex;
              width: 480px;
              .imgBox{
                margin-right: 30px;
                img{
                  width: 150px;
                  height: auto;
                  box-shadow: 7px 7px 0px #f6f6f6;
                  border-radius: 50%;
                }
              }
              dl{
                text-align: left;
              }
              dt{
                font-size: 20px;
                font-weight: 500;
                color: #2c6e34;
                margin-bottom: 12px;
                line-height: 1.0;
                background: url(../img/contents/guide/icon_comt.png) no-repeat left center;
                background-size: 24px auto;
                padding-left: 30px;
              }
              dd{
                font-size: 16px;
                font-weight: 500;
                line-height: 1.8;
                text-align: justify;
              }
            }
          }
          .movies{
            width: 480px;
            float: right;
            margin-top: -44px;
            .imgMovie{
              margin-bottom: 20px;
              height: 270px;
              iframe{
                width: 480px;
                height: 270px;
              }
            }
            .imgLower{
              display: flex;
              li{
                margin-right: 20px;
                &:nth-of-type(2){
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
    .grey{
      margin-top: 25px;
    }
  }
  .contBox03{
    background: url(../img/contents/guide/bg_02.png) no-repeat center center;
    background-size: cover;
    height: 396px;
    padding-top: 40px;
    img{
      margin-bottom: 30px;
    }
  }
}

/*
お得な墓石完成セット　と　カテゴリ
-------------------------------------*/
#PageMihon.pageIndex,
#PageMihon.pageCategory {
  /*メインイメージ　ダミー*/
  #MainImg{
    #MainImgInner{
      background: url(../img/contents/mihon/main_visu_mihon.png) no-repeat center center;
      background-size: cover;
    }
  }
  .contBox01{
    padding: 51px 0 82px;
    .lowerTitle{
      margin-bottom: 5px;
    }
    .contSubBox01{
      p{
        text-align: center;
      }
      .contWrap{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        dl{
          width: 312px;
          dt{
            background-color: #8fd001;
            color: #fff;
            font-size: 32px;
            font-weight: 700;
            border-radius: 8px 8px 0 0;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
              font-size: 16px;
              font-weight: 400;
              font-family: 'Noto Sans', sans-serif;
              margin-right: 7px;
            }
          }
          dd{
            border-bottom: 6px solid #8fd001;
            border-right: 6px solid #8fd001;
            border-left: 6px solid #8fd001;
            color: #2c6e34;
            font-size: 18px;
            font-weight: 700;
            border-radius: 0 0 8px 8px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1.6;
          }
        }
      }
    }
  }
  .contBox02{
    background: url(../img/contents/mihon/bg_01.png) repeat left top;
    background-size: 100px auto;
    padding: 40px 0;
    .contSubBox01{
      dl{
        background-color: #fff;
        border-bottom: 1px solid #dfdfdf;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
        &:last-of-type{
          border-bottom: none;
        }
        dt{
          font-size: 16px;
          font-weight: 700;
          color: #2c6e34;
          width: 199px;
          height: 60px;
          border-right: 1px solid #dfdfdf;
          float: left;
          line-height: 60px;
          letter-spacing: 0.09em;
        }
        dd{
          border-right: 1px solid #dfdfdf;
          text-align: left;
          float: left;
          &:last-of-type{
            border-right: none;
          }
          a{
            color: #000;
            text-decoration: none;
            display: block;
            background: url(../img/contents/arrow04.png) no-repeat right 15px center;
            background-size: auto 11px;
            padding-left: 20px;
            width: 199px;
            height: 60px;
            line-height: 60px;
            letter-spacing: 0.09em;
            &:hover{
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
  .contBox03{
    padding: 70px 0 100px;
    .contSubBox01{
      .post{
        margin-bottom: 35px;
        .post_item{
          width: 485px;
          float: left;
          margin-bottom: 25px;
          &:nth-of-type(even){
            margin-left: 30px;
          }
          .post_item_cont{
            border: 1px solid #dfdfdf;
            padding: 9px 20px 24px;
            text-decoration: none;
            display: block;
            text-align: left;
            .imgBox{
              width: 135px;
              display: inline-block;
              margin-top: 10px;
              margin-right: 19px;
              float: left;
              height: 232px;
              /*img{
                width: 100%;
                height: auto;
              }*/
            }
            .boxWrapTop{
              float: left;
              max-width: 287px;
              .titleBox{
                text-align: left;
                line-height: 1.6;
                .title{
                  font-size: 32px;
                  font-weight: 500;
                  color: #2c6e34;
                  display: inline-block;
                  .hurigana{
                    font-size: 14px;
                    font-weight: 500;
                    color: #888888;
                    display: block;
                    margin-top: -9px;
                  }
                }
                .area{
                  color: #89c800;
                  border: 1px solid #89c800;
                  display: inline-block;
                  vertical-align: top;
                  padding: 0 8px;
                  margin-top: 9px;
                  margin-left: 9px;
                  font-size: 18px;
                }
              }
              .labelBox{
                text-align: left;
                .label{
                  color: #89c800;
                  background-color: #f3f3f3;
                  display: inline-block;
                  font-size: 12px;
                  font-weight: 700;
                  padding: 0 10px;
                  margin-right: 5px;
                  &:last-of-type{
                    margin-right: 0;
                  }
                }
              }
            }
            .boxWrapBottom{
              float: left;
              max-width: 287px;
              width: 100%;
              .priceBox{
                margin-bottom: 17px;
                max-width: 287px;
                .price{
                  font-size: 48px;
                  font-weight: 700;
                  color: #2c6e34;
                  letter-spacing: -0.02em;
                  line-height: 1.5;
                  text-align: center;
                  span{
                    font-size: 24px;
                    letter-spacing: 0.01em;
                    position: relative;
                    &::after{
                      content:"(税込)";
                      display: block;
                      position: absolute;
                      top: -18px;
                      left: 15px;
                      font-size: 12px;
                      font-weight: 500;
                      color: #888888;
                      white-space: nowrap;
                    }
                  }
                }
                .costDown{
                  border: 1px solid #da1515;
                  color: #da1515;
                  text-align: center;
                  font-weight: 700;
                  margin-top: -6px;
                  line-height: 1.5;
                }
              }
              .linkBtn{
                width: 100%;
                text-align: center;
                padding: 8px 0 8px 7px;
              }
            }
          }
        }
      }
      .moreBtn{
        width: 480px;
      }
    }
  }
}


@supports (paint-order: fill) and (-webkit-marquee-speed: 0) {
  #PageMihon.pageIndex .contBox03 .contSubBox01 .post .post_item .post_item_cont .boxWrapBottom .priceBox .price,
  #PageMihon.pageCategory .contBox03 .contSubBox01 .post .post_item .post_item_cont .boxWrapBottom .priceBox .price {
    //property: value;
    //color: yellow;
    letter-spacing: -0.08em;
    span{
      //color: orange;
      letter-spacing: -0.09em;
    }
  }
}




/*
記事詳細
-------------------------------------*/
#PageMihon.pageEntry {
  /*メインイメージ　ダミー*/
  #MainImg{
    #MainImgInner{
      background: url(../img/contents/mihon/main_visu_mihon.png) no-repeat center center;
      background-size: cover;
    }
  }
  .contBox01{
    padding: 85px 0 62px;
    .boxWrapHeader{
      border-bottom: 1px solid #dfdfdf;
      padding-bottom: 3px;
      .titleBox{
        text-align: left;
        float: left;
        .title{
          font-size: 32px;
          font-weight: 700;
          color: #2c6e34;
          display: inline-block;
          position: relative;
          letter-spacing: 0.05em;
          .hurigana{
            font-size: 14px;
            font-weight: 500;
            color: #888888;
            display: block;
            position: absolute;
            top: -13px;
            left: 0;
            white-space: nowrap;
          }
        }
        .size{
          color: #89c800;
          border: 1px solid #89c800;
          display: inline-block;
          vertical-align: top;
          padding: 0 8px;
          margin-top: 13px;
          margin-left: 13px;
          font-size: 18px;
          line-height: 1.8;
        }
      }
      .labelBox{
        text-align: left;
        float: right;
        margin-top: 17px;
        .label{
          color: #89c800;
          background-color: #f3f3f3;
          display: inline-block;
          font-size: 12px;
          font-weight: 700;
          padding: 3px 10px;
          margin-right: 5px;
          &:last-of-type{
            margin-right: 0;
          }
        }
      }
    }
    .boxWrapBody01{
      padding: 40px 0;
      border-bottom: 1px solid #dfdfdf;
      .slideBox{
        background-color: #f6f6f6;
        padding: 20px;
        width: 570px;
        float: left;
        .gallery_top{
          .slick-slide{
              height: auto;
            }
          .slide{
            //padding-top: 70.4vw;
            height: 330px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            &:hover{
              //opacity: 0.7;
            }
          }
          .slick-prev{
            left: 20px;
            background: url(../img/contents/mihon/arrow_left.png) no-repeat center center;
            background-size: 14px auto;
            width: 14px;
            height: 34px;
            z-index: 1;
          }
          .slick-next{
            right: 20px;
            background: url(../img/contents/mihon/arrow_right.png) no-repeat center center;
            background-size: 14px auto;
            width: 14px;
            height: 34px;
          }
          .slick-prev:before, .slick-next:before{
            content: "";
          }
        }
        .gallery_thumbs{
          .slider_thumbs{
            margin: 8px auto 0;
            width: 100%;
            .slick-slide{
              height: auto;
              &.slick-current{
                .slide{
                  opacity: 1.0;
                }
              }
            }
            .slide{
              opacity: 0.6;
              //padding-top: 12vw;
              width: 97%;
              height: 60px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
              &:hover{
                opacity: 0.7;
                cursor: pointer;
              }
            }
          }
          /*.slick-slider .slick-track{
            transform: unset!important;
          }*/
          .slick-prev:before, .slick-next:before{
            display: none;
          }
        }
      }
      .infoBoxTop{
        width: 400px;
        float: left;
        margin-left: 30px;
        .areaBox{
          text-align: left;
          border-bottom: 1px solid #dfdfdf;
          margin-top: -15px;
          margin-bottom: 15px;
          .areaTitle{
            font-size: 32px;
            font-weight: 500;
            color: #2c6e34;
            display: inline-block;
          }
          .area{
            color: #89c800;
            border: 1px solid #89c800;
            display: inline-block;
            vertical-align: top;
            padding: 0 10px;
            margin-top: 13px;
            margin-left: 13px;
            font-size: 18px;
            line-height: 1.8;
          }
        }
        .priceBox{
          .price{
            border-bottom: 1px solid #dfdfdf;
            font-size: 48px;
            font-weight: 700;
            color: #da1515;
            letter-spacing: 0.01em;
            line-height: 1.5;
            padding-bottom: 10px;
            .unit{
              letter-spacing: 0.01em;
              font-size: 24px;
              font-weight: 500;
              color: #000;
              b{
                color: #da1515;
                font-weight: 700;
              }
              .grey{
                font-size: 22px;
              }
            }
          }
          .tb{
            table{
              width: 100%;
            }
            th{
              width: 50%;
            }
            td{
              width: 50%;
              text-align: right;
            }
          }
          .tb01{
            padding: 20px;
            table{
              font-size: 16px;
              font-weight: 500;
              th{
                font-weight: 500;
              }
            }
          }
          .tb02{
            background-color: #f6fbe8;
            padding: 10px 20px;
            margin-bottom: 30px;
            table{
              font-size: 16px;
              font-weight: 700;
              color: #2c6e34;
            }
          }
          .grey{
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
    .boxWrapBody02{
      padding: 30px 0;
      .infoBoxBottom{
        display: flex;
        .textEntry{
          width: 50%;
          padding-right: 30px;
          border-right: 1px solid #dfdfdf;
        }
        .comment{
          display: flex;
          width: 50%;
          padding: 10px 0 0 30px;
          .imgBox{
            margin-right: 30px;
            img{
              width: 150px;
              height: auto;
              box-shadow: 7px 7px 0px #f6f6f6;
              border-radius: 50%;
              border: 4px solid #89c800;
            }
          }
          dl{
            text-align: left;
          }
          dt{
            font-size: 20px;
            font-weight: 500;
            color: #2c6e34;
            margin-bottom: 12px;
            line-height: 1.0;
            background: url(../img/contents/guide/icon_comt.png) no-repeat left center;
            background-size: 24px auto;
            padding-left: 30px;
          }
          dd{
            font-size: 16px;
            font-weight: 500;
            line-height: 1.8;
          }
        }
      }
    }
  }
}

@supports (paint-order: fill) and (-webkit-marquee-speed: 0) {
  #PageMihon.pageEntry .contBox01 .boxWrapBody01 .infoBoxTop .priceBox .price{
    //color: yellow;
    letter-spacing: -0.04em;
    span{
      letter-spacing: -0.06em;
    }
  }
}

  /*
  永代供養墓
  -------------------------------------*/
  #PageEternal.pageIndex {
    /*メインイメージ　ダミー*/
    #MainImg{
      #MainImgInner{
        background: url(../img/contents/eternal/main_visu_eternal_02.jpg) no-repeat center center;
        background-size: cover;
        #MainTitle{
          top: 46.5%;
        }
      }
    }
    .navCont{
      margin: 80px auto 130px;
      .navItem{
        a{
          width: 249px;
        }
      }
    }
    .card{
      dt{
        background-color: #8fd001;
        border-radius: 8px 8px 0 0;
        border: 6px solid #8fd001;
        color: #fff;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.7;
        letter-spacing: 0.1em;
      }
      dd{
        border-right: 6px solid #8fd001;
        border-left: 6px solid #8fd001;
        border-bottom: 6px solid #8fd001;
        border-radius: 0 0 8px 8px;
        letter-spacing: 0.1em;
        background-color: #fff;
      }
    }
    .calc{
      position: relative;
      &:last-of-type{
        &::after{
          display: none;
        }
      }
      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: -35px;
        transform: translateY(-50%);
        background: url(../img/contents/eternal/eternal_icon02.png) no-repeat center center;
        background-size: 20px auto;
        width: 20px;
        height: 20px;
      }
    }
    .ans{
      position: relative;
      &::after{
        content: "";
        display: block;
        position: absolute;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
        background: url(../img/contents/eternal/eternal_icon03.png) no-repeat center center;
        background-size: 12px auto;
        width: 12px;
        height: 20px;
      }
    }
    .flow{
      position: relative;
      &:last-of-type{
        &::after{
          display: none;
        }
      }
      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: -30px;
        transform: translateY(-50%);
        background: url(../img/contents/eternal/eternal_icon04.png) no-repeat center center;
        background-size: 12px auto;
        width: 12px;
        height: 24px;
      }
    }
    .eql{
      position: relative;
      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: -30px;
        transform: translateY(-50%);
        background: url(../img/contents/eternal/eternal_icon05.png) no-repeat center center;
        background-size: 20px auto;
        width: 20px;
        height: 12px;
      }
    }
    .tableBox{
      table{
        tr{
          th{
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            width: 200px;
            background-color: #f1fbd5;
            font-size: 16px;
            font-weight: 700;
            color: #2c6e34;
            vertical-align: middle;
            text-align: center;
            line-height: 1.5;
            letter-spacing: 0.08em;
            height: 100px;
            &:last-of-type{
              border-right: none;
            }
          }
          td{
            border-right: 1px solid #fff;
            padding: 10px 20px;
            font-size: 16px;
            font-weight: 500;
            background-color: #f6f6f6;
            vertical-align: middle;
            text-align: center;
            line-height: 1.5;
            letter-spacing: 0.08em;
            &:last-of-type{
              border-right: none;
            }
          }
        }
      }
    }
    /*-- 共通 --*/
    .contBox{
      margin-bottom: 160px;
      &:last-of-type{
        margin-bottom: 0;
      }
      .contSubBox01{
        .numIconWrap{
          display: block;
          background: #f4fddc;
          background: -moz-linear-gradient( #e5faab 0%, #f4fddc 100%);
          background: -webkit-gradient(linear, left top, left bottom, from(#e5faab), to(#f4fddc));
          background: -webkit-linear-gradient( #e5faab 0%, #f4fddc 100%);
          background: -o-linear-gradient( #e5faab 0%, #f4fddc 100%);
          background: linear-gradient( #e5faab 0%, #f4fddc 100%);
          padding: 55px 0 45px;
          position: relative;
          .numIcon{
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);
          }
          .numIconTitle{
            font-size: 38px;
            letter-spacing: 0.1em;
            width: 100%;
            text-align: center;
            margin-left: 0;
            span{
              font-size: 24px;
            }
          }
        }
        .imgBox{
          margin-bottom: 40px;
        }
        .diagBox{
          .diagTitle{
            font-size: 32px;
            font-weight: 500;
            color: #2c6e34;
            margin-bottom: 25px;
            letter-spacing: 0.1em;
          }
          .panelBox{
            .cardBox{
              /*display: flex;
              justify-content: space-between;
              margin-bottom: 50px;*/
              /*.calc{
                width: 300px;
                dd{
                  height: 213px;
                  &.iconA{
                    padding-top: 20px;
                    img{
                      width: 210px;
                    }
                  }
                  &.iconB{
                    padding-top: 30px;
                    img{
                      width: 114px;
                    }
                  }
                  &.iconC{
                    padding-top: 10px;
                    img{
                      width: 100px;
                    }
                  }
                }
              }*/
              /*.flow{
                width: 300px;
                dt{
                  font-size: 24px;
                  font-weight: 400;
                  height: 90px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  line-height: 1.2;
                }
                dd{
                  height: 245px;
                  &.iconA{
                    padding-top: 30px;
                    img{
                      width: 150px;
                    }
                  }
                  &.iconB{
                    padding-top: 30px;
                    img{
                      width: 96px;
                    }
                    span{
                      margin-top: 20px;
                    }
                  }
                  &.iconC{
                    padding-top: 25px;
                    img{
                      width: 68px;
                    }
                    span{
                      margin-top: 20px;
                    }
                  }
                  span{
                    display: block;
                    line-height: 1.5;
                    margin-top: 10px;
                  }
                }
              }*/
            }
          }
          .cardBox{
            display: flex;
            justify-content: space-between;
            margin-bottom: 50px;
            .calc{
              width: 300px;
              dt{
                font-size: 24px;
              }
              dd{
                height: 213px;
                &.iconA{
                  padding-top: 20px;
                  img{
                    width: 210px;
                  }
                }
                &.iconB{
                  padding-top: 30px;
                  img{
                    width: 114px;
                  }
                }
                &.iconC{
                  padding-top: 10px;
                  img{
                    width: 100px;
                  }
                }
              }
            }
          }
          .ans{
            width: 620px;
            margin: 0 auto;
            dt{
              font-size: 28px;
            }
            dd{
              font-size: 24px;
              font-weight: 700;
              color: #2c6e34;
              padding: 4px 0;
            }
          }
        }
      }
      .contSubBox02{
        text-align: left;
        padding-bottom: 35px;
        .itemList{
          dt{
            border-left: 6px solid #8fd001;
            text-align: left;
            color: #2c6e34;
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0.1em;
            padding-left: 10px;
            line-height: 1.0;
            margin-bottom: 8px;
          }
          dd{
            font-size: 20px;
            font-weight: 500;
            text-align: left;
            background: url(../img/contents/eternal/eternal_icon01.png) no-repeat left center;
            background-size: 26px auto;
            padding-left: 40px;
          }
        }
        .infoBox{
          border-bottom: 1px solid #dfdfdf;
          padding-bottom: 30px;
          padding-top: 48px;
          &:last-of-type{
            border-bottom: none;
          }
          .title{
            font-size: 32px;
            color: #2c6e34;
            display: inline-block;
          }
          .label{
            display: inline-block;
            margin-top: 13px;
            margin-left: 13px;
            border: 1px solid #89c800;
            padding: 0 8px;
            line-height: 1.8;
            vertical-align: top;
            font-size: 18px;
            color: #89c800;
          }
          li{
            margin-top: 15px;
            margin-bottom: 20px;
            display: flex;
            .imgBox{
              width: 325px;
              img{
                width: 325px;
              }
            }
            .tableBox{
              table{
                height: 202px;
              }
            }
            .name{
              font-size: 16px;
              font-weight: 700;
              color: #2c6e34;
              letter-spacing: 0.06em;
              margin-top: 4px;
              text-align: left;
              position: relative;
              small{
                color: #000;
                font-size: 12px;
                position: absolute;
                right: 0;
                bottom: 0;
              }
            }
            .note{
              font-size: 14px;
              letter-spacing: 0.1em;
              margin-top: 6px;
              text-align: right;
            }
          }
        }
      }
      .contSubBox03{
        padding: 85px 0 30px;
        .flowTitle{
          font-size: 32px;
          font-weight: 500;
          color: #2c6e34;
          text-align: left;
          margin-bottom: 15px;
          letter-spacing: 0.1em;
        }
        .panelBox{
          .cardBox{
            display: flex;
            justify-content: space-between;
            margin-bottom: 50px;
            .flow{
              width: 300px;
              dt{
                font-size: 24px;
                font-weight: 400;
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1.2;
              }
              dd{
                height: 245px;
                &.iconA{
                  padding-top: 30px;
                  img{
                    width: 150px;
                  }
                }
                &.iconB{
                  padding-top: 30px;
                  img{
                    width: 96px;
                  }
                  span{
                    margin-top: 20px;
                  }
                }
                &.iconC{
                  padding-top: 25px;
                  img{
                    width: 68px;
                  }
                  span{
                    margin-top: 20px;
                  }
                }
                span{
                  display: block;
                  line-height: 1.5;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
    /*-- 個別 --*/
    .contBox01{
      .contSubBox01 {
        .diagBox{
          .cardBox{
            .card {
              &.calc{
                dt{
                  padding: 20px 0;
                }
                dd{
                  height: 245px;
                  &.iconA{
                    padding-top: 30px;
                    img{
                      width: 107px;
                    }
                  }
                  &.iconB{
                    padding-top: 30px;
                    img{
                      width: 96px;
                    }
                  }
                  &.iconC{
                    padding-top: 25px;
                    img{
                      width: 175px;
                    }
                  }
                  span{
                    display: block;
                    line-height: 1.5;
                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
    /*-- 個別 --*/
    .contBox02{
      background-color: #f6f6f6;
      .tableBox table tr {
        th{
          border-right: 1px solid #e0f4a8;
          border-bottom: 1px solid #f6f6f6;
          &:last-of-type{
            border-right: none;
          }
        }
        td{
          background-color: #fff;
          border-right: 1px solid #f6f6f6;
        }
      }
      .contSubBox01{
        .diagBox{
          margin-bottom: 50px;
          .cardBox{
            .calc{
              dt{
                padding: 3px 0;
              }
              dd{
                height: 245px;
                &.iconA{
                  padding-top: 30px;
                  img{
                    width: 175px;
                  }
                }
                &.iconB{
                  padding-top: 20px;
                  img{
                    width: 85px;
                  }
                }
                &.iconC{
                  padding-top: 20px;
                  img{
                    width: 175px;
                  }
                }
                span{
                  display: block;
                  line-height: 1.5;
                  margin-top: 25px;
                }
              }
            }
          }
        }
      }
      .contSubBox02{
        padding-bottom: 50px;
        .itemList{
          margin-top: 55px;
          dd{
            background: none;
            display: flex;
            padding-left: 0;
            b{
              width: 50%;
              span{
                display: block;
                font-size: 20px;
                font-weight: 500;
                text-align: left;
                background: url(../img/contents/eternal/eternal_icon01.png) no-repeat left center;
                background-size: 26px auto;
                padding-left: 40px;
              }
            }
          }
        }
        .infoBox{
          padding-bottom: 40px;
          &:last-of-type{
          }
          .title{
            span{
              font-size: 24px;
            }
          }
          .label{
          }
          li{
          }
        }
      }
      .contSubBox03{
        padding: 50px 0 30px;
        .flowTitle{
        }
        .panelBox{
          .cardBox{
            .flow{
              &::after{

              }
              dt{
                font-size: 24px;
                font-weight: 700;
                height: 60px;
              }
              dd{
                &.iconA{
                  padding-top: 45px;
                  img{
                    width: 230px;
                  }
                }
                &.iconB{
                  padding-top: 20px;
                  img{
                    width: 85px;
                  }
                }
                &.iconC{
                  padding-top: 20px;
                  img{
                    width: 175px;
                  }
                }
                span{
                }
              }
            }
          }
        }
      }
    }
    .contBox03{
      border-bottom: none;
      .contSubBox01{
        .imgBox{
          margin-bottom: 0;
        }
        .diagBox{
          .cardBox{
            .eql{
              width: 475px;
              &.card02{
                &::after{
                  display: none;
                }
              }
              dd{
                height: 213px;
                &.iconA{
                  padding-top: 20px;
                  img{
                    width: 235px;
                  }
                }
              }
              .iconTxt{
                font-size: 24px;
                font-weight: 700;
                color: #2c6e34;
                text-align: center;
                display: block;
                padding: 90px 0;
                line-height: 1.7;
                border: 6px solid #8fd001;
                border-radius: 8px;
              }
            }
          }
        }
      }
      .contSubBox02{
        padding-bottom: 0;
        .infoBox{
          padding-bottom: 40px;
          &:last-of-type{
          }
          .title{
            span{
              font-size: 24px;
            }
          }
          li{
            .tableBox{
              width: 100%;
              table{
                width: 100%;
              }
            }
          }
        }
      }
      .contSubBox03{
        padding: 45px 0 90px;
        .flowTitle{
          margin-bottom: 10px;
        }
        .introBox{
          display: flex;
          text-align: left;
          .itemList{
            width: 50%;
            &:nth-of-type(odd){
              margin-right: 15px;
            }
            &:nth-of-type(even){
              margin-left: 15px;
            }
            .itemImg{
              margin-bottom: 20px;
            }
            .itemTitle{
              margin-bottom: 8px;
              border-left: 6px solid #8fd001;
              padding-left: 10px;
              line-height: 1.0;
              text-align: left;
              letter-spacing: .1em;
              font-size: 20px;
              font-weight: 700;
              color: #2c6e34;
            }
            dd{
              font-size: 16px;
              font-weight: 500;
              line-height: 1.8;
            }
          }
        }
      }
    }
    /*-- 個別 --*/
    .contBox04{
      margin-bottom: 160px;
      .contSubBox{
        background-color: #f6f6f6;
      }
      .tableBox table tr {
        th{
          border-right: 1px solid #e0f4a8;
          border-bottom: 1px solid #f6f6f6;
          &:last-of-type{
            border-right: none;
          }
        }
        td{
          background-color: #fff;
          border-right: 1px solid #f6f6f6;
        }
      }
      .contSubBox01 {
        .diagBox{
          .cardBox{
            .card {
              &.calc{
                dt{
                  padding: 20px 0;
                }
                dd{
                  height: 265px;
                  &.iconA{
                    padding-top: 30px;
                    img{
                      width: 165px;
                    }
                  }
                  &.iconB{
                    padding-top: 30px;
                    img{
                      width: 96px;
                    }
                  }
                  &.iconC{
                    padding-top: 25px;
                    img{
                      width: 68px;
                    }
                  }
                  span{
                    display: block;
                    line-height: 1.5;
                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  ご納骨・ご法要
  -------------------------------------*/
  #PageGohouji {
    /*メインイメージ　ダミー*/
    #MainImg{
      #MainImgInner{
        background: url(../img/contents/gohouji/main_visu_gohouji.png) no-repeat center center;
        background-size: cover;
        #MainTitle{
          top: 46.5%;
        }
      }
    }
  }

  /*
  ご納骨・ご法要
  -------------------------------------*/
  #PageGohouji.pageIndex {
    .dot{
      position: relative;
      padding-left: 18px;
      line-height: 2.1;
      &::after{
        content: "";
        position: absolute;
        top: 12px;
        left: 0;
        display: block;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: #8fd001;
      }
    }
    .contBox01{
      background: url(../img/contents/gohouji/bg_img01.png) repeat center center;
      background-size: 4px auto;
      padding: 30px 0 60px;
      .contSubBox01{
        width: 860px;
        margin: 0 auto;
        h3{
          color: #fff;
          font-size: 20px;
          font-weight: 700;
          text-align: left;
          margin-bottom: 10px;
        }
        ul{
          background-color: #fff;
          padding: 30px 40px 34px;
          li{
            text-align: left;
            font-weight: 500;
            margin-bottom: 17px;
            &:last-of-type{
              margin-bottom: 0;
            }
            .red{
              font-weight: 700;
            }
          }
        }
      }
    }
    .contBox02{
      padding-top: 80px;
      .navCont{
        margin-bottom: 30px;
        .navItem{
          a{
            width: 499px;
            line-height: 1.0;
            padding-bottom: 20px;
          }
        }
      }
    }
    .contBox02,
    .contBox03{
      .lowerTitle{
        padding-bottom: 45px;
        border-bottom: 1px solid #dfdfdf;
      }
      .contSubBox01{
        text-align: left;
        padding-top: 45px;
        display: flex;
        .diagBox{
          width: 50%;
          margin-right: 15px;
          .diagTitle{
            font-size: 32px;
            font-weight: 500;
            color: #2c6e34;
            margin-bottom: 10px;
          }
          .diagCont{
            li{
              border: 1px solid #8fd001;
              border-radius: 8px;
              text-align: center;
              color: #8fd001;
              font-size: 18px;
              font-weight: 700;
              padding: 16px 0;
              margin-bottom: 41px;
              position: relative;
              &::after{
                content: "";
                display: block;
                background: url(../img/contents/gohouji/arrow01.png) no-repeat center center;
                background-size: 40px auto;
                width: 40px;
                height: 20px;
                position: absolute;
                bottom: -30px;
                left: 50%;
                transform: translateX(-50%);
              }
              &:last-of-type{
                margin-bottom: 0;
                &::after{
                  display: none;
                }
              }
              .grey{
                font-size: 14px;
                font-weight: 500;
              }
              .smallText{
                font-size: 14px;
              }
            }
          }
        }
        .itemBox{
          width: 50%;
          margin-left: 15px;
          .itemTitle{
            font-size: 32px;
            font-weight: 500;
            color: #2c6e34;
            margin-bottom: 10px;
          }
          .itemCont{
            border: 1px solid #8fd001;
            border-radius: 8px;
            padding: 23px 30px 35px;
            ul{
              margin-bottom: 13px;
              li{
                line-height: 1.9;
                .grey{
                  font-size: 14px;
                }
              }
            }
            .btnWrap{
              .linkBtn{
                text-align: center;
                width: 100%;
                border-radius: 8px;
                padding: 12px 0 12px 7px;
              }
            }
          }
        }
      }
    }
    .contBox02{
      .contSubBox01{
        .itemBox{
          .itemCont{
            .btnWrap{
              margin-bottom: 12px;
              &:last-of-type{
                margin-bottom: 0;
              }
              .tel{
                background-color: #8fd001;
                border-radius: 8px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0 15px;
                b{
                  text-align: center;
                  margin-right: 24px;
                  .telText{
                    font-size: 30px;
                    font-weight: 700;
                    display: block;
                    line-height: 1.5;
                  }
                  .telTime{
                    font-size: 14px;
                    font-weight: 500;
                    border: 1px solid #fff;
                    display: block;
                    line-height: 1.5;
                    letter-spacing: 0em;
                    padding: 0 5px;
                  }
                }
                .telNum{
                  font-family: 'Cinzel', serif;
                  font-size: 32px;
                  font-weight: bold;
                  margin-top: 7px;
                  letter-spacing: 0em;
                }
              }
            }
          }
        }
      }
    }
    .contBox03{
      padding: 50px 0 60px;
      .contSubBox02,
      .contSubBox03,
      .contSubBox04,
      .contSubBox05,
      .contSubBox06{
        margin-bottom: 35px;
        .contTitle{
          font-size: 32px;
          font-weight: 500;
          color: #2c6e34;
          text-align: left;
          border-bottom: 1px solid #dfdfdf;
          margin-bottom: 20px;
          .grey{
            font-size: 24px;
            font-weight: 400;
          }
        }
        .imgBox{
          display: flex;
          margin-top: 30px;
          margin-bottom: 30px;
          li{
            width: 50%;
            &:nth-of-type(odd){
              margin-right: 15px;
            }
            &:nth-of-type(even){
              margin-left: 15px;
            }
          }
        }
      }
      .contSubBox01{
        margin-bottom: 40px;
        .diagBox{
          .diagCont{
            li{
              margin-bottom: 69px;
              &::after{
                bottom: -45px;
              }
            }
          }
        }
        .itemBox{
          .itemCont{
            .btnWrap{
              margin-bottom: 12px;
              &:last-of-type{
                margin-bottom: 0;
              }
              .web{
                background: url(../img/contents/icon_blank02.png) no-repeat right 21px center;
                background-size: 16px auto;
                background-color: #8fd001;
                border-radius: 8px;
                color: #fff;
                display: block;
                width: 203px;
                padding: 16px 20px 25px;
                text-decoration: none;
                float: left;
                b{
                  text-align: center;
                  .webText{
                    font-size: 24px;
                    font-weight: 700;
                    display: block;
                    text-align: left;
                    line-height: 1.5;
                  }
                  .webTime{
                    font-size: 12px;
                    font-weight: 500;
                    border: 1px solid #fff;
                    display: block;
                    line-height: 1.5;
                    letter-spacing: 0.3em;
                    padding: 0 5px;
                    width: 130px;
                  }
                }
              }
              .tel{
                background-color: #8fd001;
                border-radius: 8px;
                color: #fff;
                display: block;
                padding: 5px 0px 4px;
                width: 203px;
                text-align: center;
                float: right;
                b{
                  text-align: center;
                  display: block;
                  margin-right: 0;
                  .telText{
                    font-size: 24px;
                    font-weight: 700;
                    display: block;
                    line-height: 1.5;
                  }
                  .telTime{
                    font-size: 12px;
                    font-weight: 500;
                    border: 1px solid #fff;
                    display: block;
                    line-height: 1.5;
                    letter-spacing: 0em;
                    padding: 0 5px;
                    width: 163px;
                    margin: 0 auto;
                  }
                }
                .telNum{
                  font-family: 'Cinzel', serif;
                  font-size: 24px;
                  font-weight: bold;
                  letter-spacing: 0.04em;
                  line-height: 1.2;
                }
              }
            }
          }
        }
      }
      .contSubBox03{
        .imgBox{
          margin-bottom: 20px;
          li{
            dl{
              dt{
                font-size: 20px;
                font-weight: 700;
                color: #2c6e34;
                text-align: left;
                padding-left: 10px;
                border-left: 6px solid #8fd001;
                line-height: 1.0;
                margin-bottom: 20px;
              }
              dd{
                &.price{
                  font-size: 16px;
                  text-align: left;
                  margin: 12px 0 20px;
                  .grey{
                    font-size: 14px;
                  }
                  &:last-of-type{
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  清風リモート法要・墓参
  -------------------------------------*/
  #PageGohouji.pageRemote {
    .dot{
      position: relative;
      padding-left: 18px;
      line-height: 2.1;
      text-align: left;
      &::after{
        content: "";
        position: absolute;
        top: 12px;
        left: 0;
        display: block;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: #8fd001;
      }
    }
    .lowerTitle{
      margin-bottom: 45px;
    }
    .contTitle{
      font-size: 32px;
      font-weight: 500;
      color: #2c6e34;
      text-align: left;
      border-bottom: 1px solid #dfdfdf;
      margin-bottom: 20px;
      .grey{
        font-size: 24px;
        font-weight: 400;
      }
    }
    //共通
    table{
      tr{
        th{
          background-color: #f1fbd5;
          color: #2c6e34;
          font-size: 16px;
          font-weight: 700;
          border-bottom: 1px solid #fff;
          padding: 14px 20px;
          width: 200px;
        }
        td{
          font-size: 16px;
          font-weight: 500;
          border-bottom: 1px solid #dfdfdf;
          border-right: 1px solid #dfdfdf;
          padding: 10px 20px;
          .grey{
            font-size: 14px;
          }
        }
        &:first-of-type{
          th{
            border-top: 1px solid #f1fbd5;
          }
          td{
            border-top: 1px solid #dfdfdf;
          }
        }
        &:last-of-type{
          th{
            border-bottom: #f1fbd5;
          }
        }
      }
    }
    .greyBox{
      background-color: #f8f8f8;
      text-align: left;
      padding: 22px 30px;
      dt{
        color: #2c6e34;
        font-size: 16px;
        font-weight: 700;
      }
      dd{
        color: #888888;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .contactBox{
      .btnWrap{
        .tel{
          .telText{
            font-size: 24px;
            display: block;
            line-height: 1.2;
            margin-right: 10px;
          }
          b{
            line-height: 1.2;
            .telNum{
              font-size: 36px;
              font-weight: bold;
              font-family: 'Cinzel', serif;
              display: block;
              border-bottom: 1px solid #74a900;
              margin-bottom: 10px;
              letter-spacing: 0.04em;
            }
            .telTime{
              font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
              font-weight: 500;
              display: block;
              margin-bottom: 6px;
              letter-spacing: 0.3em;
            }
          }

        }
        .mail{
          font-size: 24px;
          font-weight: 500;
          text-decoration: none;
          &:hover{
            opacity: 0.7;
            cursor: pointer;
          }
          span{
            background: url(../img/contents/procedure/icon_mail.png) no-repeat left center;
            background-size: 32px auto;
            padding-left: 40px;
            letter-spacing: 0.06em;
            margin-left: 10px;
          }
        }
      }
    }
    .contBox01,
    .contBox02{
      .contSubBox01{
        margin-bottom: 45px;
        display: flex;
        .textBox{
          width: 50%;
        }
        .itemBox{
          width: 50%;
          margin-left: 40px;
          .itemTitle{
            font-size: 20px;
            font-weight: 700;
            color: #2c6e34;
            letter-spacing: 0.1em;
          }
          .itemCont{
            border: 1px solid #8fd001;
            border-radius: 8px;
            padding: 10px 29px 17px;
            .itemList{
              li{
                line-height: 1.9;
                letter-spacing: 0.12em;
                .grey{
                  font-size: 14px;
                }
              }
            }
          }
          .itemImg{
            display: flex;
            margin-top: 13px;
            li{
              width: 200px;
              &:nth-of-type(1){
                margin-right: 20px;
              }
            }
          }
        }
      }
      .contSubBox02{
        ul{
          display: flex;
          margin-top: 30px;
          margin-bottom: 35px;
          li{
            border-right: 1px solid #dfdfdf;
            padding-left: 30px;
            &:nth-of-type(1){
              padding-left: 0;
            }
            &:last-of-type{
              border-right: none;
            }
            dl{
              width: 323px;
              .numIconWrap{
                .numIcon{
                  font-size: 16px;
                  font-weight: 700;
                  padding-top: 0;
                  line-height: 80px;
                }
                .numIconTitle{
                  font-size: 24px;
                  font-weight: 700;
                  padding-right: 15px;
                  .grey{
                    font-size: 18px;
                  }
                }
              }
              dd{
                font-size: 16px;
                text-align: left;
                padding: 15px 20px 0 0;
                .grey{
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .contSubBox03{
        .textBox{
          margin-bottom: 20px;
        }
        .infoBox{
          margin-bottom: 60px;
          li{
            margin-bottom: 40px;
            dl{
              dt{
                border-left: 6px solid #8fd001;
                padding-left: 10px;
                line-height: 1.0;
                text-align: left;
                font-size: 20px;
                font-weight: 700;
                color: #2c6e34;
                margin-bottom: 20px;
                margin-top: 30px;
                letter-spacing: 0.1em;
                .grey{
                  font-size: 16px;
                  font-weight: 700;
                }
              }
              dd{
                font-size: 16px;
                font-weight: 500;
                text-align: left;
                margin-bottom: 20px;
              }
            }
            table{
              width: 100%;
            }
          }
        }
      }
    }
    .contBox01{
      padding: 50px 0 80px;
      border-bottom: 1px solid #dfdfdf;
      .contSubBox03{
        .infoBox{
          margin-bottom: 30px;
          table{
            width: 590px;
            float: left;
          }
          .contactBox{
            width: 380px;
            float: right;
            .btnWrap{
              margin-bottom: 20px;
              &.telWrap{
                margin-right: 20px;
              }
              &.mailWrap{
                margin-left: 20px;
              }
              .tel{
                display: block;
                padding: 16px 60px 11px;
                .telNum{
                  font-size: 40px;
                  letter-spacing: 0;
                  padding-bottom: 6px;
                  margin-bottom: 14px;
                }
              }
              .mail{
                padding: 10px 0;
              }
            }
          }

        }
      }
    }
    .contBox02{
      padding: 50px 0 100px;
      .contSubBox03{
        .contactBox{
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;
          .btnWrap{
            width: 50%;
            &.telWrap{
              margin-right: 20px;
            }
            &.mailWrap{
              margin-left: 20px;
            }
            .tel{
              display: flex;
              justify-content: space-around;
              align-items: center;
              padding: 12px 20px;
            }
            .mail{
              padding: 27px 20px;
            }
          }
        }
      }
    }

  }

  /*
  各種手続き
  -------------------------------------*/
  #PageProcedure.pageIndex {
    /*---メインイメージ---*/
    #MainImg{
      #MainImgInner{
        background: url(../img/contents/procedure/main_visu_procedure.png) no-repeat center center;
        background-size: cover;
        #MainTitle{
          font-size: 54px;
          letter-spacing: 0.35em;
          margin-left: 12px;
        }
      }
    }
    .contBox01{
      padding: 50px 0 20px;
      .lowerTitle{
        margin-bottom: 18px;
        &+p{
          text-align: center;
          font-size: 20px;
          line-height: 1.8;
          span{
            font-size: 16px;
          }
        }
      }
      .navCont{
        margin: 60px 0 78px;
      }
      .pdfIcon{
        padding-right: 28px;
        position: relative;
        &::after{
          content: "";
          display: block;
          background: url(../img/contents/procedure/icon_pdf.png) no-repeat right center;
          background-size: 22px auto;
          width: 22px;
          height: 23px;
          position: absolute;
          top: -6px;
          right: 3px;
        }
      }
      .contSubBox{
        margin-bottom: 60px;
        h4{
          font-size: 32px;
          color: #2c6e34;
          border-bottom: 1px solid #dfdfdf;
          text-align: left;
          line-height: 2.1;
          margin-bottom: 17px;
          letter-spacing: 0.04em;
          span{
            font-size: 24px;
          }
        }
        h5{
          font-size: 20px;
          font-weight: 500;
          color: #2c6e34;
          text-align: left;
        }
        p{
          text-align: left;
        }
        .linkBtn{
          text-align: center;
          margin-top: 60px;
        }
        .greenBox{
          background-color: #f6fbe8;
          text-align: left;
          padding: 20px 30px;
          margin-top: 10px;
          margin-bottom: 18px;
          .dot{
            position: relative;
            padding-left: 15px;
            line-height: 2.1;
            color: #000000;
            font-size: 16px;
            &::after{
              content: "";
              position: absolute;
              top: 14px;
              left: 0;
              display: block;
              border-radius: 50%;
              width: 6px;
              height: 6px;
              background-color: #8fd001;
            }
          }
          .arrow{
            padding-left: 15px;
            margin-bottom: 15px;
          }
          .circle{
            margin-top: 15px;
          }
          dt{
            font-size: 16px;
            font-weight: 700;
            color: #2c6e34;
          }
          dd{
            font-size: 16px;
          }
          ul{
            li{
              font-size: 16px;
            }
          }
        }
        .greyBox{
          background-color: #f8f8f8;
          text-align: left;
          padding: 21px 30px;
          margin-top: 20px;
          dt{
            font-size: 16px;
            font-weight: 700;
            color: #2c6e34;
          }
          dd{
            p{
              font-size: 14px;
            }
          }
        }
      }
      .contSubBox01{
        .spaceLeft{
          padding-left: 15px;
        }
      }
      .contSubBox04{
        .spaceBtm{
          margin-bottom: 15px;
        }
      }
      .contSubBox05{
        .boxWrap{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0;
          .leftBox{
            width: 470px;
            //float: left;
            p{
              text-align: center;
            }
            &>p{
              margin-bottom: 10px;
            }
            .linkBtn{
              width: 470px;
            }
          }
          .rightBox{
            width: 470px;
            //float: right;
            .frameBtnGrn{
              text-align: center;
              padding: 12px 30px 10px 20px;
            }
            .tel{
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin-bottom: 20px;
              .telText{
                font-size: 24px;
                display: block;
                line-height: 1.2;
                margin-right: 10px;
              }
              b{
                line-height: 1.2;
                .telNum{
                  font-size: 36px;
                  font-weight: bold;
                  font-family: 'Cinzel', serif;
                  display: block;
                  border-bottom: 1px solid #74a900;
                  margin-bottom: 10px;
                  letter-spacing: 0.04em;
                }
                .telTime{
                  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                  font-weight: 500;
                  display: block;
                  margin-bottom: 6px;
                  letter-spacing: 0.3em;
                }
              }
            }
            .mail{
              font-size: 24px;
              font-weight: 500;
              text-decoration: none;
              span{
                background: url(../img/contents/procedure/icon_mail.png) no-repeat left center;
                background-size: 32px auto;
                padding-left: 40px;
                letter-spacing: 0.06em;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  /*
  ご見学・ご契約・施工・アフターフォローの流れ
  -------------------------------------*/
  #PageFlow.pageIndex {
    /*---メインイメージ---*/
    #MainImg{
      #MainImgInner{
        //background: url(../img/contents/flow/main_visu_flow_test02.png) no-repeat center center;
        //background-size: cover;
        #MainTitle{
          font-size: 54px;
          letter-spacing: 0em;
          margin-left: 7px;
        }
      }
    }
    .navCont{
      padding-top: 78px;
      .navItem {
        a {
          width: 249px;
          line-height: 1.2;
          padding-bottom: 20px;
        }
      }
    }
    .contBox{
      .contSubBox{
        padding-top: 50px;
        padding-bottom: 88px;
        .lowerTitle{
          margin-bottom: 15px;
        }
        .flow{
          .flow_list{
            padding-top: 40px;
            padding-bottom: 50px;
            border-bottom: 1px solid #dfdfdf;
            &:last-of-type{
              border-bottom: none;
              padding-bottom: 0;
            }
            .imgBox{
              width: 380px;
              height: 235px;
              span{
                display: block;
                box-shadow: 10px 10px 0 #e6e6e6;
              }
            }
            .textBox{
              text-align: left;
              position: relative;
              padding-left: 100px;
              padding-top: 18px;
              max-width: 570px;
              .numIcon {
                display: inline-block;
                min-width: 80px;
                height: 80px;
                line-height: 80px;
                background: url(../img/contents/icon_numbg.png) no-repeat center center;
                background-size: 80px auto;
                text-align: center;
                font-family: "Noto Sans", sans-serif;
                font-size: 16px;
                font-weight: bold;
                color: #fff;
                padding-top: 0;
                position: absolute;
                top: 0;
                left: 0;
              }
              .numIconTitle{
                font-size: 24px;
                font-weight: 700;
                color: #2c6e34;
                letter-spacing: 0.1em;
              }
              .numIconCont{
                line-height: 1.9;
              }
            }
          }
        }
      }
    }

    .contBox01,
    .contBox03{
      .contSubBox{
        .flow_list{
          .imgBox{
            float: left;
            margin-right: 50px;
          }
          .textBox{
            float: left;
          }
        }
      }
    }
    .contBox02,
    .contBox04{
      background: url(../img/contents/flow/bg_img01.png) repeat left top;
      background-size: 1280px auto;
      .contSubBox{
        .flow_list{
          .imgBox{
            float: right;
            margin-left: 40px;
            margin-right: 10px;
          }
          .textBox{
            float: right;
          }
        }
      }
    }
  }

  /*
  お知らせ（公式ブログ）共通
  -------------------------------------*/
  #PageBlog {
    /*---メインイメージ---*/
    #MainImg{
      #MainImgInner{
        background: url(../img/contents/blog/main_visu_blog.png) no-repeat center center;
        background-size: cover;
        #MainTitle{
          font-size: 54px;
          letter-spacing: 0.2em;
          margin-left: 7px;
          top: 46.5%;
        }
      }
    }
  }


  /*
  お知らせ（公式ブログ） 一覧・カテゴリ共通
  -------------------------------------*/
  #PageBlog.pageIndex,
  #PageBlog.pageCategory {
    .contBox01{
      padding: 38px 0 100px;
      .contSubBox01{
        width: 690px;
        float: left;
        .post{
          margin-bottom: 60px;
          &_item{
            &_cont{
              text-decoration: none;
              border-bottom: 1px solid #dfdfdf;
              display: block;
              padding: 40px 0;
              &::after{
                content: "";
                display: block;
                clear: both;
              }
              .thumb{
                width: 260px;
                height: auto;
                display: block;
                float: left;
              }
              .entry{
                display: block;
                width: 400px;
                text-align: left;
                float: right;
                .time{
                  font-size: 14px;
                  font-family: 'Noto Sans', sans-serif;
                  color: #89c800;
                  letter-spacing: 0.2em;
                  vertical-align: middle;
                }
                .label{
                  font-size: 14px;
                  font-weight: 500;
                  color: #89c800;
                  border: 1px solid #89c800;
                  display: inline-block;
                  padding: 0 8px;
                  letter-spacing: 0.1em;
                  vertical-align: middle;
                  margin-left: 4px;
                  line-height: 1.9;
                }
                .title{
                  font-size: 20px;
                  font-weight: 500;
                  color: #2c6e34;
                  text-decoration: underline;
                  margin-top: 15px;
                  display: block;
                  line-height: 1.5;
                  &:hover{
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
        .btnWrap{
          width: 480px;
          margin: 0 auto;
          .moreBtnGrn{
            &:hover{
              opacity: 0.7;
              cursor: pointer;
            }
          }
        }
        .moreBtn{
          background: none;
        }
      }
    }
  }

  /*
  お知らせ（公式ブログ） 一覧
  -------------------------------------*/
  #PageBlog.pageIndex{
    .contBox01{
      #SubBox1{
        padding-top: 40px;
      }
    }
  }

  /*
  カテゴリ
  -------------------------------------*/
  #PageBlog.pageCategory {
    .contBox01{
      padding: 64px 0 100px;
      .contSubBox01{
        .cont_title{
          color: #2c6e34;
          font-size: 32px;
          font-weight: 500;
          text-align: left;
        }
        .post{
          .post_item{
            &:first-of-type{
              .post_item_cont{
                border-top: 1px solid #dfdfdf;
              }
            }
          }
        }
      }
      #SubBox1{
        padding-top: 15px;
      }
    }
  }

  /*
  記事詳細
  -------------------------------------*/
  #PageBlog.pageEntry {
    .contBox01{
      padding: 63px 0 90px;
      .contSubBox01{
        width: 690px;
        float: left;
        .postCont{
          margin-bottom: 55px;
          &_head{
            margin-bottom: 13px;
            .label{
              color: #2c6e34;
              font-size: 32px;
              font-weight: 500;
              text-align: left;
              display: block;
              border-bottom: 1px solid #dfdfdf;
              margin-bottom: 30px;
            }
            .title{
              font-size: 24px;
              font-weight: 700;
              color: #2c6e34;
              text-align: left;
              border-left: 6px solid #8fd001;
              padding-left: 10px;
              line-height: 1.5;
            }
          }
          .postCont_main{
            border-bottom: 1px solid #dfdfdf;
          }
        }
        .btnWrap{
          position: relative;
          width: 424px;
          margin: 0 auto;
          padding-left: 12px;
          .pgBtn{
            margin-bottom: 0;
            a{
              display: block;
              color: #2c6e34;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0.08em;
              position: relative;
              &::before{
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            &.prev{
              float: left;
              a{
                padding-left: 18px;
                &::before{
                  background: url(../img/contents/blog/arrow_blog01.png) no-repeat center center;
                  background-size: 7px auto;
                  left: 0;
                }
              }
            }
            &.back{
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              a{
                padding-right: 24px;
                &::before{
                  background: url(../img/contents/blog/arrow_blog02.png) no-repeat center center;
                  background-size: 17px auto;
                  width: 17px;
                  height: 15px;
                  right: 0;
                }
              }
            }
            &.next{
              float: right;
              a{
                padding-right: 18px;
                &::before{
                  background: url(../img/contents/blog/arrow_blog03.png) no-repeat center center;
                  background-size: 7px auto;
                  right: 0;
                }
              }
            }
            &.prev, &.next{
              a{
                &::before{
                  width: 7px;
                  height: 14px;
                }
              }
            }
          }
        }
      }
      #SubBox1{
        padding-top: 20px;
      }
    }
  }

  /*
  見学予約・資料請求 共通
  -------------------------------------*/
  #PageContact {
    /*---メインイメージ---*/
    #MainImg{
      #MainImgInner{
        background: url(../img/contents/contact/main_visu_contact.png) no-repeat center center;
        background-size: cover;
        #MainTitle{
          font-size: 54px;
          letter-spacing: 0.2em;
          margin-left: 7px;
          top: 46.5%;
        }
      }
    }
  }


  /*
  見学予約・資料請求　.pageIndex　.pageConfirm 共通
  -------------------------------------*/
  #PageContact.pageIndex,
  #PageContact.pageConfirm {
    .hissu{
      background-color: #da1515;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      padding: 2px 5px;
      margin-right: 10px;
    }
    .ninni{
      background-color: #c0c0c0;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      padding: 1px 5px;
      margin-right: 10px;
    }
    .contBox01{
      padding: 70px 0 85px;
      p{
        font-weight: 500;
        .grey{
          font-size: 14px;
        }
      }
      .contSubBox01{
        width: 840px;
        margin: 0 auto;
        form{
          margin-top: 30px;
          table{
            width: 100%;
            margin-bottom: 40px;
            tr{
              border-bottom: 1px solid #dfdfdf;
              &:first-of-type{
                border-top: 1px solid #dfdfdf;
              }
              th{
                padding: 36px 0;
                letter-spacing: 0.04em;
                vertical-align: top;
                width: 223px;
                font-size: 16px;
                font-weight: 500;
                .grey{
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              td{
                padding: 30px 0 29px;
                font-size: 16px;
                font-weight: 500;
                input{
                  border: 1px solid #dfdfdf;
                  padding: 8px 20px;
                  border-radius: 0;
                  &::placeholder{
                    color: #c0c0c0;
                  }
                }
                textarea{
                  border: 1px solid #dfdfdf;
                  width: 100%;
                  height: 240px;
                  padding: 8px 20px;
                  text-align: left;
                  border-radius: 0;
                }
                .checker{
                  border: 1px solid #dfdfdf;
                  width: 30px;
                  height: 30px;
                  span{
                    width: 100%;
                    height: 100%;
                    &.checked{
                      background: url(../img/contents/contact/contact_icon01.png) no-repeat center center;
                      background-size: 24px auto;
                    }
                    input{
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
              &#Purpose{
                th{
                  padding: 30px 0 29px;
                }
                td{
                  padding: 30px 0 29px;
                  label{
                    margin-left: 10px;
                    margin-right: 12px;
                    &:last-of-type{
                      margin-right: 0;
                    }
                  }
                }
              }
              &#Name,
              &#Hurigana{
                td{
                  input{
                    width: 420px;
                  }
                }
              }
              &#Tel{
                td{
                  input{
                    width: 280px;
                  }
                }
              }
              &#Email{
                td{
                  input{
                    width: 520px;
                  }
                }
              }
              &#Calendar{
                td{
                  &:nth-of-type(1){
                    width: 293px;
                  }
                  &:nth-of-type(2){
                    padding-left: 30px;
                  }
                  input{
                    width: 100%;
                    background: url(../img/contents/contact/contact_icon02.png) no-repeat right 10px center;
                    background-size: 10px auto;
                    color: #000000;
                    &::placeholder{
                      color: #000000;
                    }
                  }
                  .selector{
                    border: 1px solid #dfdfdf;
                    width: 100%!important;
                    background: url(../img/contents/contact/contact_icon02.png) no-repeat right 10px center;
                    background-size: 10px auto;
                    span{
                      padding: 5px 20px;
                      width: 100%!important;
                    }
                    select{
                      height: 100%;
                    }
                  }
                }
              }
              &#Address{
                td{
                  padding-bottom: 20px;
                  input{
                    width: 100%;
                  }
                  small{
                    font-size: 14px;
                    display: block;
                    margin-top: 10px;
                  }
                }
              }
              &#Bikou{
                td{
                  padding-bottom: 18px;
                }
              }
            }
          }
          .btnWrap{
            .linkBtn{
              padding: 0;
              input{
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0.2em;
                padding: 10px 0 10px 7px;
                display: block;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  /*
  見学予約・資料請求　.pageIndex
  -------------------------------------*/
  #PageContact.pageIndex {
    .contBox02{
      text-align: left;
      padding-bottom: 55px;
      .contTitle{
        font-size: 32px;
        font-weight: 500;
        color: #2c6e34;
        text-align: left;
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 20px;
      }
      .contSubBox02{
        .textBox{
          .textItem{
            margin-bottom: 35px;
            h4{
              font-size: 16px;
              font-weight: 700;
              color: #2c6e34;
              margin-bottom: 16px;
            }
            p{
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 16px;
            }
            .dotBox{
              .dot{
                position: relative;
                padding-left: 18px;
                line-height: 2.0;
                font-size:16px;
                &::after{
                  content: "";
                  position: absolute;
                  top: 12px;
                  left: 0;
                  display: block;
                  border-radius: 50%;
                  width: 6px;
                  height: 6px;
                  background-color: #8fd001;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  送信内容確認ページ（見学予約・資料請求）
  -------------------------------------*/
  #PageContact.pageConfirm {
    .contBox01{
      padding: 70px 0 105px;
      .contSubBox01{
        form {
          table {
            tr {
              th{
                width: 105px;
                padding: 30px 0 25px;
              }
              td{
                padding: 30px 0 25px;
              }
            }
          }
        }
        .btnWrap{
          position: relative;
          .back{
            font-size: 14px;
            display: inline-block;
            background: url(../img/contents/contact/contact_icon03.png) no-repeat right center;
            background-size: auto 14px;
            padding-right: 22px;
            position: absolute;
            top: 12px;
            left: 0;
            color: #2c6e34;
            text-decoration: underline;
            &:hover{
              //opacity: 0.7;
              text-decoration: none;
            }
          }
        }
      }
    }
    //エラー表示
    .err{
      padding: 90px 15px;
      input{
        color: #2c6e34;
        text-decoration: underline;
        &:hover{
          text-decoration: none;
        }
      }
    }
    .error_messe{
      text-align: center;
    }
  }

  /*
  送信完了ページ（見学予約・資料請求）
  -------------------------------------*/
  #PageContact.pageThanks {
    .contBox01{
      padding: 50px 0 80px;
      .lowerTitle{
        margin-bottom: 35px;
      }
      .contSubBox01{
        p{
          text-align: center;
          margin-bottom: 15px;
        }
        .btnWrap{
          margin-top: 50px;
        }
      }
    }
  }


  /*
  ページ404
  -------------------------------------*/
  #Page404 {
    /*---メインイメージ---*/
    #MainImg{
      #MainImgInner{
        background: url(../img/contents/blog/main_visu_blog.png) no-repeat center center;
        background-size: cover;
        #MainTitle{
          font-size: 54px;
          letter-spacing: 0.2em;
          margin-left: 7px;
          top: 46.5%;
        }
      }
    }
    .contBox{
      padding: 100px 15px;
      .notF{
        text-align: center;
        margin-bottom: 60px;
      }
      a{
        text-decoration: none;
        color: #fff;
      }
    }
  }


@media print, screen and (max-width: 1060px) {
  #Page.pageIndex.fixed #Header h1{
    left: 0;
  }
  #Page.pageIndex.fixed #Header .navBox{
    right: 0;
  }
}



@media print,
screen and (min-width: 768px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #ContBox06 #ContSubBox06 {
      /*min-width: 1000px;*/
    }
    #ContBox07 #ContSubBox07 .bnr{
      /*min-width: 1000px;*/
      //background-size: 201px auto;
      &.bnr02 .innerBasic{
      }
    }

  }

  /*
  霊園概要
  -------------------------------------*/
  #PageAbout.pageIndex {
  }

  /*
  各種墓所のご案内
  -------------------------------------*/
  #PageGuide.pageIndex {
  }

  /*
  お得な墓石完成セット
  -------------------------------------*/
  #PageMihon.pageIndex {
  }

  /*
  カテゴリ
  -------------------------------------*/
  #PageMihon.pageCategory {
  }

  /*
  記事詳細
  -------------------------------------*/
  #PageMihon.pageEntry {
    .contBox01 .boxWrapBody02 .infoBoxBottom .comment .imgBox{
      min-width: 150px;
    }
  }

  /*
  永代供養墓
  -------------------------------------*/
  #PageEternal.pageIndex {
    #Main{
      .contBox04{
        margin-bottom: 160px;

      }
    }
  }

  /*
  ご納骨・ご法要
  -------------------------------------*/
  #PageGohouji.pageIndex {
  }

  /*
  清風リモート法要・墓参
  -------------------------------------*/
  #PageGohouji.pageRemote {
  }

  /*
  各種手続き
  -------------------------------------*/
  #PageProcedure.pageIndex {
    
  }

  /*
  ご見学・ご契約・施工・アフターフォローの流れ
  -------------------------------------*/
  #PageFlow.pageIndex {
    /*---メインイメージ---*/
    #MainImg{
      #MainImgInner{
        background: url(../img/contents/flow/main_visu_flow.png) no-repeat center center;
        background-size: cover;
        #MainTitle{
          font-size: 54px;
          letter-spacing: 0em;
          margin-left: 7px;
        }
      }
    }
  }

  /*
  お知らせ（公式ブログ）
  -------------------------------------*/
  #PageBlog.pageIndex {
  }

  /*
  カテゴリ
  -------------------------------------*/
  #PageBlog.pageCategory {
  }

  /*
  記事詳細
  -------------------------------------*/
  #PageBlog.pageEntry {
  }

  /*
  見学予約・資料請求
  -------------------------------------*/
  #PageContact.pageIndex {
  }

  /*
  送信内容確認ページ（見学予約・資料請求）
  -------------------------------------*/
  #PageContact.pageConfirm {
  }

  /*
  送信完了ページ（見学予約・資料請求）
  -------------------------------------*/
  #PageContact.pageThanks {
  }


}


@media screen and (max-width: 1070px) {
  #Header .nav02 a{
    font-size: 13px;
  }
}
@media screen and (max-width: 1040px) {
  #Header .nav02 a{
    font-size: 12px;
  }
}


@media print,
screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    
    #MainImg{
      /*---犬---*/
      .animalbg{
        position: absolute;
        top: 10px;
        left: 0;
        width: 216px;
        height: auto;
        z-index: 0;
      }
    }

    #MenuButton{
      background-image: url(../img/header/icon_menu01.png);
      position: absolute;
    }
    &.active #MenuButton{
      background-image: url(../img/header/icon_menu02.png);
      position: fixed;
    }
    /*ヘッダー追従時*/
    &.fixed #Header{
      height: 55px;
    }
    &.fixed #Header h1{
      position: absolute;
      top: 3px;
      left: 17px;
      margin-bottom: 0;
    }
    &.fixed #Header h1 a{
      background: url(../img/header/logo_scroll.png) no-repeat center center;
      height: 50px;
      width: 225px;
      background-size: 225px auto;
    }
    &.fixed #MenuButton{
      background-image: url(../img/header/icon_menu01_02.png);
      position: fixed;
    }
    &.fixed.active #MenuButton{
      background-image: url(../img/header/icon_menu02.png);
    }

    &.fixed #Container{
      margin-top: 170px;
    }

    /*---スライド1枚目イラスト---*/
    #MainImg .bxslider li:first-child .illust01{
      top: 90px;
      left: calc(50% + 140px);
      img{
        width: 50px;
        height: auto;
      }
    }
    /*---スライド2枚目イラスト---*/
    #MainImg .bxslider li:first-child .illust02{
      top: 230px;
      left: calc(50% - 170px);
      img{
        width: 57px;
        height: auto;
      }
    }


    /*---バラ---*/
    #Header .r_illust {
        right: 0;
        left: auto;
        top: -90px;
        /*z-index: 10;*/
        width: 98px;
    }
    #Header .l_illust {
        left: 0;
        right: auto;
        top: -90px;
        /*z-index: 10;*/
        width: 98px;
    }

    

    #MainImg{
      height: 530px;
    }

    #Header{
      padding-top: 25px;
    }
    #Header h1{
      margin-bottom: 35px;
    }
    #Header h1 a{
      background-size: 201px auto;
      height: 114px;
    }
    #MainImg #MainTitle{
      font-size: 44px;
    }

    #ContBox01{
      //padding: 40px 15px;
    }
    #ContBox01 .bgPost{
      padding: 40px 15px;
    }
    #ContBox01 table{
      width: 100%;
    }
    #ContBox01 table th{
      display: block;
      width: 100%;
      padding: 10px 10px 10px 14px;
    }
    #ContBox01 table td{
      display: block;
      width: 100%;
      padding:20px 20px;
    }
    #ContBox01 .post .post_item{
      margin-bottom: 10px;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    #ContBox01 .post .post_item_cont .time{
      float: none;
      display: block;
    }
    #ContBox01 .post .post_item_cont .title{
      float: none;
      width: 100%;
    }

    #ContBox02{
      padding: 32px 15px;
      .bgGrass{
        height: 118px;
      }
    }
    #ContBox02 .h_title{
      font-size: 30px;
      margin-bottom: 28px;
    }
    #ContBox02 #ContSubBox02{
      padding: 35px 20px 100px;
    }
    #ContBox02 #ContSubBox02 figure{
      width: calc(100% + 10px);
      margin-left: -5px;
      margin-top: 4px;
      margin-bottom: 32px;
      img{
        width: 100%;
        height: auto;
      }
    }
    /*#ContBox02 #ContSubBox02 h3{
      margin-bottom: 22px;
    }*/
    #ContBox02 #ContSubBox02 p{
      text-align: justify;
    }
    #ContBox02 #ContSubBox02 .frameBtnOrg{
      width: 100%;
      margin-top: 30px;
      padding: 11px 15px;
      line-height: 1.8;
      text-align: center;
    }
    #ContBox02 #ContSubBox02 .leaf-l{
      background-size: 143px auto;
      bottom: 80px;
        left: -85px;
    }
    #ContBox02 #ContSubBox02 .leaf-r{
      background-size: auto 110px;
      top: -85px;
        right: -85px;
    }
    #ContBox03{
      padding: 45px 0 50px;
    }
    #ContBox03 .h_title{
      font-size: 30px;
      line-height: 1.35;
    }
    #ContBox03 .en_title{
      margin-top: 7px;
    }
    #ContBox03 #ContSubBox03{
      /*background-position: left 15px top -5px;
      background-size: 593px auto;*/
      background: none;
      padding: 0 15px;
    }
    #ContBox03 #ContSubBox03 .modal{
      width: 100%;
      //padding-top: 440px;
      padding-top: 30px;
      margin-bottom: 31px;
      display: flex;
      flex-wrap: wrap;
    }
    #ContBox03 #ContSubBox03 .modal_list{
      width: 50%;
      height: auto;
      margin-right: 0;
      margin-bottom: 10px;
      &:nth-of-type(odd){
        padding-right: 5px;
        .modal_title{
          background-position: right 15px center;
        }
      }
      &:nth-of-type(even){
        padding-left: 5px;
        .modal_title{
          padding-left: 15px;
        }
      }
    }
    #ContBox03 #ContSubBox03 .modal_title{
      font-size: 15px;
      background-size: 21px auto;
    }
    #ContBox04{
      padding: 37px 15px 50px;
    }
    #ContBox04 .h_title{
      font-size: 30px;
      line-height: 1.5;
      margin-bottom: 34px;
    }
    #ContBox04 .en_title{
      margin-top: 7px;
    }
    #ContBox04 #ContSubBox04 .grave{
      margin-bottom: 0;
      display: block;
    }
    #ContBox04 #ContSubBox04 .grave_list{
      //float: none;
      margin-right: 0;
      margin-bottom: 34px;
      display: block;
      .imgBox{
        margin: 0 auto 31px;
        width: 265px;
        height: 265px;
        /*img{
          width: 100%;
          height: 100%;
        }*/
        .imgBoxCont{
          width: 100%;
          height: 100%;
        }
      }
      .grave_rubi{
        margin-left: 0;
      }
      .grave_title{
        margin-left: 0;
      }
    }
    #ContBox05{
      padding: 32px 0 50px;
    }
    #ContBox05 .h_title {
      font-size: 30px;
      margin-left: 0;
      margin-bottom: 35px;
    }
    #ContBox05 #ContSubBox05 .flow{
      margin-bottom: 4px;
    }
    #ContBox05 #ContSubBox05 .flow .flow_list{
      background: url(../img/contents/bg_img05_01_sp.png) no-repeat left 45px top;
      background-size: 1px auto;
    }
    #ContBox05 #ContSubBox05 .flow_list:nth-of-type(odd){
      padding-right: 25px;
    }
    #ContBox05 #ContSubBox05 .flow_list:nth-of-type(even){
      padding-left: 25px;
      .innerBasic{
        margin-left: -10px;
      }
    }
    #ContBox05 #ContSubBox05 .flow .flow_list01{
      padding-top: 0;
      //padding-bottom: 30px;
      padding-bottom: 0;
      background: none;
      dl{
        background: url(../img/contents/bg_img05_01_sp.png) repeat-y left 45px top;
        background-size: 1px auto;
        padding-bottom: 30px;
      }
    }
    #ContBox05 #ContSubBox05 .flow_list02{
      padding-top: 0;
      padding-bottom: 30px;
      background: none;
    }
    #ContBox05 #ContSubBox05 .flow_list03{
      padding-top: 0;
      padding-bottom: 30px;
      background: none;
    }
    #ContBox05 #ContSubBox05 .flow_list04{
      padding-top: 0;
      padding-bottom: 30px;
      background: none;
    }
    #ContBox05 #ContSubBox05 .flow .flow_list05{
      padding-top: 0;
      padding-bottom: 30px;
      background: none;
      dl{
        background: url(../img/contents/bg_img05_02_sp.png) no-repeat left 45px top 0;
        background-size: 1px auto;
        padding-top: 33px;
      }
    }
    #ContBox05 #ContSubBox05 .flow_list .imgBox{
      position: static;
      width: 100%;
      height: 216px;
      margin-bottom: 43px;
      background: none;
      height: auto;
    }
    #ContBox05 #ContSubBox05 .flow_list05 .imgBox{
      margin-bottom: 10px;
    }
    #ContBox05 #ContSubBox05 .flow_list dl{
      width: 100%;
    }
    #ContBox05 #ContSubBox05 .flow_list:nth-of-type(odd) dl{
      padding-left: 90px;
    }
    #ContBox05 #ContSubBox05 .flow_list:nth-of-type(even) dl{
      padding-left: 75px;
      padding-right: 15px;
      margin: 0;
    }
    #ContBox05 #ContSubBox05 .flow_list dl dt{
      font-size: 24px;
      margin-bottom: 4px;
    }
    #ContBox05 #ContSubBox05 .flow_list dl dt .stepIcon{
      width: 61px;
      height: 61px;
      font-size: 12px;
      top: -15px;
        left: -75px;
        padding-top: 23px;
    }
    #ContBox05 #ContSubBox05 .flow_list dl dt .stepIcon b::after{
      width: 53px;
      height: 53px;
      top: -38px;
    }
    /*#ContBox05 #ContSubBox05 .flow_list dl dd{
      width: calc(100% + 5px);
    }*/

    #ContBox05 #ContSubBox05 .btnWrap{
      padding: 0 15px;
    }


    #ContBox06{
      padding: 32px 15px 50px;
    }
    #ContBox06 .h_title {
        line-height: 1.5;
        margin-bottom: 20px;
    }
    #ContBox06 .en_title{
      margin-top: 4px;
    }
    #ContBox06 #ContSubBox06 .map{
      margin-bottom: 20px;
      a{
        background-size: 19px auto;
      }
    }
    #ContBox06 #ContSubBox06 .map iframe{
      height: 260px;
    }
    #ContBox06 #ContSubBox06 .access{
      display: block;
      margin-bottom: 40px;
    }
    #ContBox06 #ContSubBox06 .access_Box{
      width: 100%;
      .frameBtnGrn{
        display: block;
      }
    }
    #ContBox06 #ContSubBox06 .access_Box01{
      padding-right: 0;
      margin-bottom: 30px;
    }
    #ContBox06 #ContSubBox06 .access_Box01 .frameBtnGrn{
      width: 100%;
      line-height: 1.2;
      padding: 15px 0 7px;
      b{
        margin-left: 0;
      }
    }
    #ContBox06 #ContSubBox06 .access_Box01 .access_list01{
      margin-bottom: 23px;
      dd{
        line-height: 1.9;
      }
    }
    #ContBox06 #ContSubBox06 .access_Box01 .access_list02{
      padding-top: 0;
      .access_bus_img{
        position: static;
        width: 100%;
      }
      table{
        margin-top: 8px;
        margin-bottom: 28px;
      }
    }
    #ContBox06 #ContSubBox06 .access_bus{
      background-size: 28px auto;
    }
    #ContBox06 #ContSubBox06 .access_Box01 .access_bus_img{
      padding-left: 0;
    }
    #ContBox06 #ContSubBox06 .access_Box01 .access_bus_img img{
      width: 100%;
      padding-right: 12px;
      margin-top: 10px;
      margin-bottom: -4px;
    }
    #ContBox06 #ContSubBox06 .access_Box02 p{
      text-align: left;
      margin-bottom: 6px;
    }
    #ContBox07 #ContSubBox07 .bnr01{
      padding: 40px 15px;
    }
    #ContBox07 #ContSubBox07 .bnr01 .innerBasic{
      display: block;
    }
    #ContBox07 #ContSubBox07 .bnr01 a{
      margin-bottom: 20px;
    }
    #ContBox07 #ContSubBox07 .bnr01 a:last-of-type{
      margin-bottom: 0;
    }
    #ContBox07 #ContSubBox07 .bnr01 img{
      width: 100%;
    }
    #ContBox07 #ContSubBox07 .bnr02{
      padding: 40px 0 45px;
    }
    #ContBox07 #ContSubBox07 .bnr02 .innerBasic{
      display: block;
      padding: 0;
    }
    #ContBox07 #ContSubBox07 .bnr02 a {
      width: 250px;
      margin: 0 auto 15px;
    }
    #ContBox07 #ContSubBox07 .bnr02 a:last-of-type {
      margin-bottom: 0;
    }
    #ContBox07 #ContSubBox07 .bnr02 img {
      width: 100%;
    }
  }

  /*
  霊園概要
  -------------------------------------*/
  #PageAbout.pageIndex {

    //video
    #MainImg {
      #MainImgInner{

        .video{
          height: 375px;
        }
      }
    }

    .contBox01 {
      padding: 44px 0 0;
      .lowerTitle{
        margin-bottom: 35px;
        &::after{
          top: 103px;
        }
      }
      .lowerSubTitle{
        margin-top: 20px;
      }
      .navCont{
        margin-bottom: 62px;
        padding-left: 15px;
        padding-right: 15px;
      }
      .contSubBox{
        margin-bottom: 50px;
      }
      .pointCont {
        padding: 25px 0 60px;
        .pointItem{
          margin-bottom: 40px;
          &:nth-of-type(odd){
            padding: 0 15px 0 25px;
            .imgBox{
              float: none;
              width: calc(100% + 40px);
              margin-left: -25px;
            }
          }
          &:nth-of-type(even){
            padding: 0 25px 0 15px;
            .imgBox{
              width: calc(100% + 40px);
              margin-left: -15px;
            }
          }
          &:last-of-type{
            margin-bottom: 0;
          }
          .imgBox{
            width: 100%;
            position: relative;
            z-index: -1;
          }
          .infoBoxWrap{
            margin-top: -25px;
            .infoBox{
              padding: 20px 30px;
            }
          }
        }
      }
      .contSubBox02{
        padding-bottom: 60px;
        margin-bottom: 50px;
        .pointCont{
          padding-bottom: 40px;
          .item_access{
            margin-bottom: 30px;
          }
          .item_freebus{
            .infoBoxWrap{
              margin-top: -60px;
            }
          }
          .diagBoxWrap{
            .diagBox{
              padding-left: 42px;
              margin-top: 24px;
              &:nth-of-type(1), &:nth-of-type(2){
                margin-bottom: 6px;
              }
              &.train{
                background: url(../img/contents/about/icon_train02.png) no-repeat left 0 top 16px;
                background-size: 35px auto;
              }
              &.walk{
                background: url(../img/contents/about/icon_walk02.png) no-repeat left 7px top 4px;
                background-size: 33px auto;
                padding-left: 50px;
              }
              &.bus{
                background: url(../img/contents/about/icon_bus02.png) no-repeat left 0 top 13px;
                background-size: 35px auto;
              }
              &.car{
                background: url(../img/contents/about/icon_car02.png) no-repeat left 9px top 14px;
                background-size: 35px auto;
                padding-left: 50px;
              }
              dt{
                font-size: 15px;
              }
              dd{
                font-size: 10px;
                span{
                  font-size: 25px;
                  b{
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }
      .contSubBox03{
         .img{
          margin-top: 15px;
        }
        .pointItem{
          &:nth-of-type(2){
            .imgBox{
              border: none;
            }
          }
        }
      }
      //レイアウト
      .contSubBox04{
        .pointCont{
          .pointItem{
            .infoBoxWrap{
              .infoBox{
                padding: 20px 11px 20px 30px;
              }
            }
          }
        }
        .setRight{
          display: block;
          .space{
            float: right;/*画像の位置を右下にしたい場合はright*/
            clear: both;/*.spaceのfloat解除*/
            margin: 1.2em 0 0 1.2em;/*画像の周りの余白*/
          }
          .img{
            float: right;/*画像の位置を右下にしたい場合はright*/
            clear: both;/*.spaceのfloat解除*/
            margin: 0.1em 1em 0 1.2em;/*画像の周りの余白*/
          }
        }
      }
      .btnWrap{
        padding: 0 15px;
      }
    }
    .contBox02{
      padding: 35px 0 0;
      .lowerTitle{
        margin-bottom: 35px;
      }
      .contSubBox01{
        .map{
          margin-bottom: 33px;
          iframe{
            height: 240px;
          }
          .mapText{
            padding: 0 15px;
            p{
              &:nth-of-type(2){
                float: none;
                margin-top: 15px;
                text-align: right;
                display: block;
              }
            }
          }
        }
         .access{
          display: block;
          margin-bottom: 80px;
         }
        .access_Box01{
          width: 100%;
          padding: 0 15px;
          dd{
            line-height: 1.9;
          }
          .access_list01{
            +.diagBox{
              border: 1px solid #dfdfdf;
              margin-top: 20px;
              margin-bottom: 25px;
              padding: 28px 10px 40px;
              p{
                letter-spacing: .1em;
                font-size: 24px;
                font-weight: 700;
                color: #2c6e34;
                text-align: center;
                margin-bottom: 15px
              }
            }
          }
          .access_list03{
            .movie{
              width: 100%;
            }
          }
          .access_list04{
            .diagBox{
              width: 100%;
            }
            +.diagBox{
              margin-bottom: 25px;
            }
          }
          .access_list05{
            padding-top: 0;
            padding-right: 0;
            .access_bus_img{
              width: 100%;
              height: auto;
              padding-left: 0;
              padding-right: 12px;
              padding-top: 7px;
              position: static;
            }
            table{
              margin-top: 4px;
              margin-bottom: 30px;
            }
          }
          .frameBtnGrn{
            width: 100%;
          }
        }
        .access_Box02{
          width: 100%;
        }
      }

    }
  }





  /*
  各種墓所のご案内
  -------------------------------------*/
  #PageGuide.pageIndex {
    .contBox01{
      height: auto;
      background-image: url(../img/contents/guide/bg_03.png);
      background-position: center center;
      background-color: #2c6e34;
      background-size: cover;
      .contSubBox01{
        padding: 30px 15px 20px;
        .contWrap{
          p{
            line-height: 1.6;
            margin-bottom: 17px;
            span{
            }
          }
          ul{
            display: block;
            margin-bottom: 20px;
            li{
              margin-bottom: 36px;
              height: 60px;
              &:last-of-type{
                margin-bottom: 0;
              }
              &:nth-of-type(1), &:nth-of-type(2){
                &::after{
                  bottom: -30px;
                  top: auto;
                  right: 50%;
                  transform: translateX(50%);
                }
              }
              &:nth-of-type(1){
                padding: 10px 0;
                font-size: 21px;
              }
              &:nth-of-type(2){
                font-size: 21px;
                line-height: 0.9;
                padding: 13px 0;
              }
              &:nth-of-type(3){
                font-size: 24px;
                padding: 8px 0;
              }
            }
          }
        }
      }
    }
    .contBox02{
      padding: 15px 15px 0;
      margin-bottom: 50px;
      .guide {
        .guide_list{
          padding: 30px 0 30px;
          .titleBox{
            margin-bottom: 10px;
            .title{
              line-height: 1.8;
              margin-right: 6px;
            }
            .label{
              //margin-bottom: 10px;
            }
          }
          .contentsBox{
            display: flex;
            flex-wrap: wrap;
            .leadTxt{
              width: 100%;
              margin-bottom: 20px;
              line-height: 1.5;
              order: 1;
            }
            .infoBox{
              float: none;
              margin-right: 0;
              order: 3;
              table{
                width: 100%;
                margin-bottom: 30px;
                tr{
                  th{
                    height: 60px;
                    line-height: 1.3;
                    &:nth-of-type(1){
                      width: 23%;
                    }
                    &:nth-of-type(2){
                      width: 44%;
                    }
                    .grey{
                      font-size: 14px;
                    }
                  }
                  td{
                    //padding: 9px 0;
                    letter-spacing: 0.08em;
                    line-height: 1.3;
                    height: 60px;
                  }
                }
              }
              .comment{
                width: 100%;
                .imgBox{
                  img{
                    width: 114px;
                  }
                }
                dl{
                }
                dt{
                }
                dd{
                  text-align: justify;
                }
              }
            }
            .movies{
              width: 100%;
              float: none;
              margin-top:0;
              margin-bottom: 20px;
              order: 2;
              .imgMovie{
                margin-bottom: 20px;
                height: 195px;
                iframe{
                  width: 100%;
                  height: 195px;
                }
              }
              .imgLower{
                display: flex;
                li{
                  margin-right: 20px;
                  &:nth-of-type(2){
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }
      .grey{
        font-size: 14px;
        margin-top: 24px;
      }
    }
    .contBox03{
      height: auto;
      background: none;
      padding: 50px 15px 80px;
      padding-top: 0;
      .btnWrap{
        .linkBtn{
          font-size: 18px;
          padding-right: 32px;
          letter-spacing: 0.2em;
        }
      }
    }
  }

  /*
  お得な墓石完成セット
  -------------------------------------*/
  #PageMihon.pageIndex,
  #PageMihon.pageCategory {
    #MainImg{
      #MainImgInner{
        #MainTitle{
          line-height: 1.2;
        }
        .mainSubTitle{
          margin-top: 20px;
        }
      }
    }
    .contBox01{
      padding: 42px 0 29px;
      .lowerTitle{
        margin-bottom: 13px;
      }
      .contSubBox01{
        padding: 0 15px;
        p{
          text-align: center;
        }
        .contWrap{
          display: block;
          margin-top: 40px;
          dl{
            width: 100%;
            margin-bottom: 33px;
            dt{
              span{
              }
            }
            dd{
            }
          }
        }
      }
    }
    .contBox02{
      padding: 50px 15px;
      .contSubBox01{
        dl{
          margin-bottom: 20px;
          &:last-of-type{
            margin-bottom: 0;
          }
          dt{
            width: 100%;
            height: 60px;
            border-right: none;
            float: none;
            line-height: 60px;
            border-bottom: 1px solid #dfdfdf;
            font-size: 21px;
          }
          dd{
            border-right: none;
            float: none;
            &:last-of-type{
              border-right: none;
            }
            a{
              width: 100%;
              height: 61px;
              line-height: 60px;
              letter-spacing: 0.09em;
              border-bottom: 1px solid #dfdfdf;
              background-position: right 20px center;
              &:hover{
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
    .contBox03{
      padding: 60px 15px 80px;
      .contSubBox01{
        .post{
          margin-bottom: 45px;
          .post_item{
            width: 100%;
            float: none;
            margin-bottom: 30px;
            &:nth-of-type(even){
              margin-left: 0;
            }
            &:last-of-type{
              margin-bottom: 0;
            }
            .post_item_cont{
              padding: 9px 20px 20px;
              .imgBox{
                width: 43%;
                margin-right: 7%;
                img{
                }
              }
              .boxWrapTop{
                width: 50%;
                margin-top: 35px;
                max-width: none;
                .titleBox{
                  margin-bottom: 8px;
                  .title{
                    .hurigana{
                    }
                  }
                  .area{
                    margin-left: 0;
                  }
                }
                .labelBox{
                  text-align: left;
                  .label{
                    color: #89c800;
                    background-color: #f3f3f3;
                    display: inline-block;
                    font-size: 12px;
                    font-weight: 700;
                    padding: 0 10px;
                    margin-right: 5px;
                    &:last-of-type{
                      margin-right: 0;
                    }
                  }
                }
              }
              .boxWrapBottom{
                margin-top: 7px;
                width: 100%;
                max-width: none;
                .priceBox{
                  margin-bottom: 19px;
                  max-width: none;
                  .price{
                    font-size: 46px;
                    text-align: center;
                    white-space: nowrap;
                    span{
                      &::after{
                      }
                    }
                  }
                  .costDown{
                    margin-top: -2px;
                  }
                }
                .linkBtn{
                  width: 100%;
                  text-align: center;
                  padding: 7px 0 7px 7px;
                }
              }
            }
          }
        }
        .moreBtn{
          width: 100%;
        }
      }
    }
  }

  /*
  カテゴリ
  -------------------------------------*/
  #PageMihon.pageCategory {
  }

  /*
  記事詳細
  -------------------------------------*/
  #PageMihon.pageEntry {
    /*メインイメージ　ダミー*/
    #MainImg{
      #MainImgInner{
      }
    }
    .contBox01{
      padding: 58px 15px 50px;
      .boxWrapHeader{
        padding-bottom: 20px;
        .titleBox{
          float: none;
          margin-bottom: 10px;
          .title{
            .hurigana{
            }
          }
          .size{
          }
        }
        .labelBox{
          float: none;
          display: block;
          margin-top: -3px;
          .label{
            &:last-of-type{
            }
          }
        }
      }
      .boxWrapBody01{
        padding: 40px 0 20px;
        .slideBox{
          width: 100%;
          float: none;
          padding: 15px;
          .gallery_top{
            .slide{
              padding-top: 51.4vw;
              height: auto;
            }
            .slick-prev{
              left: 14px;
              background: url(../img/contents/mihon/arrow_left.png) no-repeat center center;
              background-size: 7px auto;
              width: 7px;
              height: 17px;
              z-index: 1;
            }
            .slick-next{
              right: 14px;
              background: url(../img/contents/mihon/arrow_right.png) no-repeat center center;
              background-size: 7px auto;
              width: 7px;
              height: 17px;
            }
          }
          .gallery_thumbs{
            margin-top: 10px;
            .slider_thumbs{
              .slide{
                padding-top: 9.5vw;
                height: auto;
              }
            }
          }
        }
        .infoBoxTop{
          width: 100%;
          float: none;
          margin-left: 0;
          padding-top: 43px;
          .areaBox{
            .areaTitle{
            }
            .area{
              margin-left: 13px;
            }
          }
          .priceBox{
            .price{
              font-size: 40px;
              text-align: center;
              padding-bottom: 15px;
              white-space: nowrap;
              letter-spacing: -0.02em;
              .unit{
                letter-spacing: 0.01em;
                font-size: 24px;
                b{
                }
                .grey{
                }
              }
            }
            .tb{
              table{
              }
              th{
              }
              td{
              }
            }
            .tb01{
              table{
              }
            }
            .tb02{
              padding: 12px 20px;
              margin-bottom: 24px;
              table{
              }
            }
            .grey{
            }
          }
        }
      }
      .boxWrapBody02{
        padding: 20px 0;
        .infoBoxBottom{
          display: block;
          .textEntry{
            width: 100%;
            padding-right: 0;
            border-right: none;
            border-bottom: 1px solid #dfdfdf;
            padding-bottom: 20px;
          }
          .comment{
            padding: 30px 0 0 0;
            width: 100%;
            .imgBox{
              margin-right: 26px;
              min-width: 114px;
              img{
                width: 114px;
              }
            }
            dl{
              text-align: left;
            }
            dt{
              font-size: 20px;
              font-weight: 500;
              color: #2c6e34;
              margin-bottom: 12px;
              line-height: 1.0;
              background: url(../img/contents/guide/icon_comt.png) no-repeat left center;
              background-size: 24px auto;
              padding-left: 30px;
            }
            dd{
              font-size: 16px;
              font-weight: 500;
              line-height: 1.8;
            }
          }
        }
      }
    }
  }

  /*
  永代供養墓
  -------------------------------------*/
  #PageEternal.pageIndex {
    /*メインイメージ　ダミー*/
    #MainImg{
      #MainImgInner{
        #MainTitle{
          top: 48.5%;
          letter-spacing: 0.1em;
          line-height: 1.5;
        }
        .mainSubTitle{
          margin-top: 10px;
        }
      }
    }
    .navCont{
      margin: 0 auto 105px;
      padding: 60px 15px 0;
      .navItem{
        a{
          width: 100%;
          span{
            margin-right: 7px;
          }
        }
      }
    }
    .card{
      dt{
      }
      dd{
      }
    }
    .calc{
      &::after{
        content: "";
        top: auto;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .ans{
      &::after{
      }
    }
    .flow{
      &::after{
        top: auto;
        bottom: -35px;
        right: 50%;
        transform: translateX(50%);
        background: url(../img/contents/eternal/eternal_icon06.png) no-repeat center center;
        background-size: 24px auto;
        width: 24px;
        height: 12px;
      }
    }
    .eql{
      &::after{
        top: auto;
        bottom: -35px;
        left: 50%;
        display: block;
        width: 12px;
        height: 20px;
        background: url(../img/contents/eternal/eternal_icon03.png) no-repeat center center;
        background-size: 12px auto;
        transform: translateX(-50%);
      }
    }
    .tableBox{
      table{
        tr{
          th{
            width: 25%;
            height: auto;
            padding: 25px 0;
          }
          td{
            padding: 27px 0;
          }
        }
      }
    }
    .contBox{
      margin-bottom: 90px;
      .contSubBox01{
        padding-bottom: 0;
        .numIconWrap{
          padding: 50px 0 18px;
          margin-bottom: 0;
          .numIcon{
          }
          .numIconTitle{
            line-height: 1.3;
            font-size: 32px;
          }
        }
        .imgBox{
          margin-bottom: 30px;
        }
        .diagBox{
          margin-bottom: 0;
          padding: 0 15px;
          .diagTitle{
          }
          .cardBox{
            display: block;
            .card{
              &.calc{
                width: 100%;
                margin-bottom: 50px;
                dt{
                  padding: 0;
                  font-size: 28px;
                }
                dd{
                  &.iconA{
                    img{
                      width: 210px;
                    }
                  }
                  &.iconB{
                    img{
                    }
                  }
                  &.iconC{
                    img{
                    }
                  }
                }
              }
            }
          }
          .ans{
            width: 100%;
            dd{
              padding: 18px 0;
              line-height: 1.2;
            }
          }
        }
        .itemList{
          padding: 0 15px;
          dt{
          }
          dd{
            background: url(../img/contents/eternal/eternal_icon01.png) no-repeat left top 5px;
            background-size: 26px auto;
            text-align: justify;
          }
        }
      }
      .contSubBox02{
        padding: 0 15px 35px;
        .itemList {
          dd{
            background-position: left top 7px;
          }
        }
        .infoBox{
          padding-top: 35px;
          &:last-of-type{
            border-bottom: none;
          }
          .title{
            display: block;
          }
          .label{
            margin-top: 0;
            margin-left: 0;
            padding: 0 10px;
            letter-spacing: 0.1em;
          }
          .infoBoxCont{
            margin-bottom: 45px;
            li{
              margin-top: 25px;
              margin-bottom: 0;
              display: block;
              .imgBox{
                width: 100%;
                margin-bottom: 15px;
                img{
                  width: 100%;
                }
              }
              .tableBox{
                table{
                  height: auto;
                  width: 100%;
                }
              }
              .name{
                margin-top: 0;
              }
              .note{
                margin-top: 0;
              }
            }
          }
        }
      }
      .contSubBox03{
        padding: 45px 15px 60px;
        .flowTitle{
          line-height: 1.2;
          margin-bottom: 30px;
        }
        .panelBox{
          .cardBox{
            display: block;
            margin-bottom: 0;
            .flow{
              width: 100%;
              margin-bottom: 50px;
              &:last-of-type{
                margin-bottom: 0;
              }
              dt{
                font-weight: 700;
              }
              dd{
                height: 250px;
                &.iconA{
                  padding-top: 25px;
                  img{
                    //width: 150px;
                  }
                }
                &.iconB{
                  padding-top: 25px;
                  img{
                    //width: 96px;
                  }
                }
                &.iconC{
                  padding-top: 20px;
                  img{
                    //width: 68px;
                  }
                }
                span{
                  line-height: 1.4;
                  margin-top: 15px;
                }
              }
            }
          }
        }
      }
    }
    .contBox01{
      padding-top: 60px;
      .contSubBox01{
        .diagBox {
          .cardBox {
            .card{
              &.calc {
                dd{
                  &.iconA{
                    height: 275px;
                    padding-top: 20px;
                    img{
                      width: 150px;
                      margin-right: 9px;
                    }
                  }
                  &.iconB{
                    height: 258px;
                    padding-top: 28px;
                    img{
                      width: 115px;
                      margin-right: 2px;
                    }
                  }
                  &.iconC{
                    height: 287px;
                    img{
                      width: 235px;
                      margin-right: 2px;
                    }
                  }
                  span{
                    margin-top: 16px;
                  }
                }
              }
            }
          }
        }
      }
      .contSubBox02{
        padding-bottom: 25px;
        .infoBox{
          padding-bottom: 0;
        }
      }
    }
    .contBox02{
      margin-bottom: 130px;
      .contSubBox01{
        .numIconWrap{
          padding: 45px 0 18px;
          margin-bottom: 0;
        }
        .imgBox{
          margin-bottom: 45px;
        }
        .diagBox{
          .diagTitle{
            line-height: 1.2;
            margin-bottom: 35px;
          }
          .cardBox{
            .card{
              &.calc{
                dd{
                  &.iconA{
                    height: 270px;
                    img{
                      width: 230px;
                    }
                  }
                  &.iconB{
                    height: 235px;
                    padding-top: 42px;
                    img{
                      //width: 85px;
                    }
                  }
                  &.iconC{
                    height: 278px;
                    img{
                      width: 235px;
                    }
                  }
                  span{
                    margin-top: 14px;
                  }
                }
              }
            }
          }
          .ans{
            dt{
              line-height: 1.2;
              padding: 7px 0 3px;
            }
          }
        }
        .itemList{
          dd{
            background: none;
            display: block;
            b{
              width: 100%;
              span{
                text-align: justify;
                background: url(../img/contents/eternal/eternal_icon01.png) no-repeat left top 7px;
                background-size: 26px auto;
              }
            }
          }
        }
      }
      .contSubBox02{
        padding-bottom: 40px;
        .infoBox{
          padding-bottom: 30px;
          .infoBoxCont{
            margin-bottom: 20px;
          }
        }
        .itemList{
          margin-top: 5px;
          dd{
            background: none;
            display: block;
            b{
              width: 100%;
              span{
                text-align: justify;
                background: url(../img/contents/eternal/eternal_icon01.png) no-repeat left top 7px;
                background-size: 26px auto;
              }
            }
          }
        }
      }
      .contSubBox03{
        padding: 45px 15px 60px;
        .panelBox{
          .cardBox{
            .flow{
              dt{
              }
              dd{
                &.iconA{
                  padding-top: 45px;
                  img{
                  }
                }
                &.iconB{
                  padding-top: 20px;
                  img{
                  }
                }
                &.iconC{
                  img{
                  }
                }
                span{
                }
              }
            }
          }
        }
      }
    }
    .contBox03{
      .contSubBox01{
        .imgBox{
          margin-bottom: 0;
        }
        .numIconWrap {
          padding: 56px 0 23px;
          .numIconTitle{
            line-height: 1.1;
          }
        }
        .diagBox{
          .cardBox{
            .eql{
              width: 100%;
              margin-bottom: 50px;
              &.card02{
                margin-bottom: 0;
              }
              dd{
                //height: 213px;
                &.iconA{
                  //padding-top: 20px;
                  img{
                    //width: 235px;
                  }
                }
              }
              .iconTxt{
                padding: 70px 0;
              }
            }
          }
        }
      }
      .contSubBox02{
        padding-bottom: 0;
        .infoBox{
          padding-bottom: 30px;
          &:last-of-type{
          }
          .infoBoxCont{
            margin-bottom: 20px;
          }
          .title{
            span{
            }
          }
          li{
            .tableBox{
              table{
                th{
                  padding: 40px 0;
                }
                td{
                  padding: 40px 0;
                }
              }
            }
          }
        }
      }
      .contSubBox03{
        padding: 36px 15px 70px;
        .flowTitle{
          margin-bottom: 25px;
        }
        .introBox{
          display: block;
          .itemList{
            width: 100%;
            margin-bottom: 30px;
            &:last-of-type{
              margin-bottom: 0;
            }
            &:nth-of-type(odd){
              margin-right: 0;
            }
            &:nth-of-type(even){
              margin-left: 0;
            }
            .itemTitle{
              margin-bottom: 10px;
            }
            dd{
            }
          }
        }
      }
    }
    .contBox04{
      .contSubBox01{
        .diagBox {
          .cardBox {
            .card{
              &.calc {
                dd{
                  &.iconA{
                    height: 275px;
                    padding-top: 20px;
                    img{
                      width: 210px;
                      margin-right: 9px;
                    }
                  }
                  &.iconB{
                    height: 258px;
                    padding-top: 28px;
                    img{
                      width: 115px;
                      margin-right: 2px;
                    }
                  }
                  &.iconC{
                    height: 287px;
                    padding-top: 10px;
                    img{
                      width: 100px;
                      margin-right: 2px;
                    }
                  }
                  span{
                    margin-top: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  ご納骨・ご法要
  -------------------------------------*/
  #PageGohouji {
    /*メインイメージ　ダミー*/
    #MainImg{
      #MainImgInner{
        #MainTitle{
          line-height: 2.0;
          top: 45.5%;
        }
      }
    }
  }
  /*
  ご納骨・ご法要
  -------------------------------------*/
  #PageGohouji.pageIndex {
    .dot{
      line-height: 2.0;
    }
    .contBox01{
      background: url(../img/contents/gohouji/bg_img01.png) repeat center center;
      background-size: 4px auto;
      padding: 30px 15px 60px;
      .contSubBox01{
        width: 100%;
        ul{
          padding: 30px 20px;
        }
      }
    }
    .contBox02{
      padding: 60px 15px;
      .navCont{
        margin-bottom: 40px;
        .navItem{
          a{
            width: 100%;
            padding: 21px 0;
          }
        }
      }
    }
    .contBox02,
    .contBox03{
      .lowerTitle{
        font-size: 40px;
        padding-bottom: 20px;
      }
      .contSubBox01{
        padding-top: 40px;
        display: block;
        .diagBox{
          width: 100%;
          margin-right: 0;
          .diagTitle{
            line-height: 1.2;
            margin-bottom: 23px;
          }
          .diagCont{
            margin-bottom: 40px;
            li{
              &::after{
              }
              &:last-of-type{
                &::after{
                }
              }
              .grey{
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }
        .itemBox{
          width: 100%;
          margin-left: 0;
          .itemTitle{
            line-height: 1.2;
            margin-bottom: 23px;
          }
          .itemCont{
            padding: 20px 20px 30px;
            ul{
              //margin-bottom: 13px;
              li{
                line-height: 1.75;
              }
            }
            .btnWrap{
              .linkBtn{
                font-size: 18px;
                line-height: 1.2;
                letter-spacing: 0.2em;
                padding: 15px 0 15px 7px;
              }
            }
          }
        }
      }
    }
    .contBox02{
      padding: 60px 15px 40px;
      .contSubBox01{
        .itemBox{
          .itemCont{
            .btnWrap{
              .tel{
                display: block;
                padding: 5px 0 0;
                text-decoration: none;
                b{
                  margin-right: 0;
                  .telText{
                    font-size: 24px;
                  }
                  .telTime{
                    font-size: 12px;
                    width: 163px;
                    margin: 0 auto;
                  }
                }
                .telNum{
                  font-size: 24px;
                  margin-top: 0;
                  line-height: 1.5;
                  display: block;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
    .contBox03{
      padding: 0px 15px 35px;
      .contSubBox02,
      .contSubBox03,
      .contSubBox04,
      .contSubBox05,
      .contSubBox06{
        .contTitle{
          .grey{
          }
        }
        .imgBox{
          display: block;
          margin-top: 20px;
          li{
            width: 100%;
            margin-bottom: 30px;
            &:nth-of-type(odd){
              margin-right: 0;
            }
            &:nth-of-type(even){
              margin-left: 0;
            }
          }
        }
      }
      .contSubBox01{
        margin-bottom: 34px;
        .diagBox{
          .diagCont{
            li{
              margin-bottom: 41px;
              &::after{
                bottom: -31px;
              }
            }
          }
        }
        .itemBox{
          .itemCont{
            .btnWrap{
              //margin-bottom: 12px;
              &:last-of-type{
                //margin-bottom: 0;
              }
              .web{
                width: 100%;
                float: none;
                margin-bottom: 15px;
                background: none;
                background-color: #8fd001;
                display: block;
                padding: 16px 20px 25px;
                b{
                  background: url(../img/contents/icon_blank02.png) no-repeat right top 24px;
                  background-size: 16px auto;
                  display: block;
                  width: 163px;
                  margin: 0 auto;
                  text-align: center;
                  .webText{
                    display: block;
                    text-align: left;
                    line-height: 1.5;
                  }
                  .webTime{
                    display: block;
                    line-height: 1.5;
                    letter-spacing: 0.3em;
                    padding: 0 5px;
                    width: 130px;
                  }
                }
              }
              .tel{
                width: 100%;
                float: none;
                display: block;
                padding: 5px 0 0;
                text-decoration: none;
                b{
                  margin-right: 0;
                  .telText{
                    font-size: 24px;
                  }
                  .telTime{
                    font-size: 12px;
                    width: 163px;
                    margin: 0 auto;
                  }
                }
                .telNum{
                  font-size: 24px;
                  margin-top: 0;
                  line-height: 1.5;
                  display: block;
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .contSubBox03{
        .imgBox{
          margin-top: 32px;
          margin-bottom: 20px;
          li{
            &:last-of-type{
              margin-bottom: 0;
            }
            dl{
              dt{
              }
              dd{
                &.price{
                  margin: 6px 0 20px;
                  &:last-of-type{
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  清風リモート法要・墓参
  -------------------------------------*/
  #PageGohouji.pageRemote {
    .lowerTitle{
      margin-bottom: 23px;
    }
    //共通
    table{
      margin-bottom: 30px;
      tr{
        th{
          padding: 14px 18px;
          width: 40%;
          vertical-align: middle;
          text-align: justify;
          letter-spacing: 0.04em;
        }
        td{
          padding: 18px 15px;
          line-height: 1.4;
          text-align: justify;
        }
      }
    }
    .greyBox{
      text-align: justify;
      padding: 22px 20px;
    }
    .contactBox{
      .btnWrap{
        .tel{
          text-decoration: none;
          .telText{
            margin-right: 0;
          }
          b{
            .telNum{
              max-width: 253px;
              margin: 0 auto;
            }
            .telTime{
              letter-spacing: 0.25em;
            }
          }

        }
        .mail{
          span{
          }
        }
      }
    }
    .contBox01,
    .contBox02{
      .contSubBox01{
        margin-bottom: 45px;
        display: block;
        padding: 0 15px 0;
        .textBox{
          width: 100%;
        }
        .itemBox{
          width: 100%;
          margin-left: 0;
          .itemTitle{
          }
          .itemCont{
            padding: 10px 20px 30px;
            margin-top: 30px;
            .itemList{
              li{
                line-height: 1.75;
                letter-spacing: 0.06em;
                text-align: justify;
              }
            }
          }
          .itemImg{
            display: block;
            li{
              width: 100%;
              &:nth-of-type(1){
                margin-right: 0;
              }
              &:nth-of-type(2){
                display: none;
              }
            }
          }
        }
      }
      .contSubBox02{
        padding: 0 15px 0;
        margin-bottom: 45px;
        ul{
          display: block;
          margin-bottom: 35px;
          li{
            border-right: none;
            border-bottom: 1px solid #dfdfdf;
            padding-left: 0;
            margin-bottom: 28px;
            &:nth-of-type(1){
              padding-left: 0;
            }
            &:last-of-type{
              border-right: none;
              margin-bottom: 0;
            }
            dl{
              width: 100%;
              .numIconWrap{
                padding: 0;
                .numIcon{
                }
                .numIconTitle{
                  padding-right: 0;
                  .grey{
                  }
                }
              }
              dd{
                padding: 15px 0 30px;
                text-align: justify;
                .grey{
                }
              }
            }
          }
        }
      }
      .contSubBox03{
        padding: 0 15px 0;
        .infoBox{
          margin-bottom: 30px;
          li{
            &:last-of-type{
              margin-bottom: 0;
              table{
                margin-bottom: 0;
              }
            }
            table{
              width: 100%;
            }
          }
        }
      }
    }
    .contBox01{
      padding: 45px 0 60px;
      .contSubBox03{
        .infoBox{
          margin-bottom: 30px;
          table{
            width: 100%;
            float: none;
          }
          .contactBox{
            width: 100%;
            float: none;
            .btnWrap{
              margin-bottom: 20px;
              &.telWrap{
                margin-right: 20px;
              }
              &.mailWrap{
                margin-left: 20px;
              }
              .tel{
                padding: 16px 0 11px;
                .telNum{
                  letter-spacing: -0.01em;
                }
              }
              .mail{
              }
            }
          }
        }
      }
    }
    .contBox02{
      padding: 45px 0 80px;
      .contSubBox03{
        .contactBox{
          display: block;
          margin-bottom: 30px;
          .btnWrap{
            width: 100%;
            &.telWrap{
              margin-right: 0;
            }
            &.mailWrap{
              margin-left: 0;
            }
            .tel{
              display: block;
              padding: 16px 0 11px;
              margin-bottom: 20px;
              b {
                .telNum{
                  font-size: 40px;
                  letter-spacing: -0.01em;
                  margin-bottom: 14px;
                  padding-bottom: 6px;
                }
              }
            }
            .mail{
              padding: 10px 0;
            }
          }
        }
      }
    }

  }

  /*
  各種手続き
  -------------------------------------*/
  #PageProcedure.pageIndex {
    /*---メインイメージ---*/
    #MainImg{
      #MainImgInner{
        #MainTitle{
          font-size: 40px;
          letter-spacing: 0.25em;
          margin-left: 7px;
          line-height: 2.0;
          top: 45.5%;
        }
        .mainSubTitle{
          letter-spacing: 0.4em;
          margin-left: -11px;
        }
      }
    }
    .contBox01{
      padding: 42px 15px 25px;
      .lowerTitle{
        margin-bottom: 15px;
        &+p{
          line-height: 1.6;
          span{
          }
        }
      }
      .navCont{
        margin: 43px 0 52px;
      }
      .contSubBox{
        margin-bottom: 56px;
        h4{
          line-height: 1.2;
          padding-bottom: 14px;
          span{
          }
        }
        p{
          text-align: justify;
        }
        .linkBtn{
          margin-top: 40px;
          line-height: 1.2;
          padding: 15px 0;
          background-position: right 40px center;
        }
        .greenBox{
          text-align: justify;
          padding: 22px 20px;
          &.greenBox04{
            ul{
              li{
                &:last-of-type{
                  margin-bottom: 0;
                }
              }
            }
          }
          &.greenBox07{
            ul{
              li{
                &:last-of-type{
                  margin-bottom: 0;
                }
              }
            }
          }
          .dot{
            &::after{
            }
          }
          .arrow{
            margin-bottom: 14px;
          }
          .circle{
            margin-top: 14px;
          }
          dt{
          }
        }
        .greyBox{
          dt{
          }
          dd{
            p{
            }
          }
        }
      }
      .contSubBox05{
        .boxWrap{
          display: block;
          padding: 0;
          .leftBox{
            width: 100%;
            margin-bottom: 40px;
            p{
              text-align: justify;
            }
            &>p{
            }
            .linkBtn{
              width: 100%;
              text-align: center;
              margin-top: 10px;
            }
          }
          .rightBox{
            width: 100%;
            .frameBtnGrn{
              padding: 15px 0 10px;
            }
            .tel{
              display: block;
              text-decoration: none;
              .telText{
                display: block;
                letter-spacing: 0.15em;
                margin-right: 0;
              }
              b{
                .telNum{
                  font-size: 40px;
                  letter-spacing: -0.01em;
                  line-height: 1.5;
                  max-width: 270px;
                  margin: 5px auto 14px;
                }
                .telTime{
                  margin-bottom: 6px;
                  letter-spacing: 0.25em;
                }
              }
            }
            .mail{
              font-size: 24px;
              font-weight: 500;
              text-decoration: none;
              padding: 10px 0;
              span{
                background: url(../img/contents/procedure/icon_mail.png) no-repeat left center;
                background-size: 32px auto;
                padding-left: 40px;
                letter-spacing: 0.06em;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  /*
  ご見学・ご契約・施工・アフターフォローの流れ
  -------------------------------------*/
  #PageFlow.pageIndex {
    /*---メインイメージ---*/
    #MainImg{
      #MainImgInner{
        background: url(../img/contents/flow/main_visu_flow_sp.png) no-repeat center center;
        background-size: cover;
        #MainTitle{
          font-size: 32px;
          line-height: 1.2;
          top: 48.5%;
        }
        .mainSubTitle {
          margin-top: 20px;
        }
      }
    }
    .navCont{
      padding: 58px 15px 0;
      .navItem {
        a {
          width: 100%;
          line-height: 1.2;
          padding: 19px 0;
        }
      }
    }
    .contBox{
      .contSubBox{
        padding-top: 50px;
        padding-bottom: 88px;
        .lowerTitle{
          //margin-bottom: 15px;
          margin-bottom: 0;
          line-height: 1.2;
        }
        .flow{
          padding-left: 15px;
          padding-right: 15px;
          .flow_list{
            padding: 40px 0 35px;
            &:last-of-type{
            }
            .imgBox{
              width: 100%;
              height: auto;
              margin-bottom: 30px;
              padding-right: 10px;
            }
            .textBox{
              max-width: auto;
              padding-top: 29px;
              .numIcon {
              }
              .numIconTitle{
                line-height: 1.2;
                margin-bottom: 8px;
              }
              .numIconCont{
              }
            }
          }
        }
      }
    }

    .contBox01,
    .contBox03{
      .contSubBox{
        .flow_list{
          .imgBox{
            float: none;
            margin-right: 0;
          }
          .textBox{
            float: none;
          }
        }
      }
    }
    .contBox02,
    .contBox04{
      .contSubBox{
        .flow_list{
          .imgBox{
            float: none;
            margin-left: 0;
            margin-right: 0;
          }
          .textBox{
            float: none;
          }
        }
      }
    }
    .contBox01{
      .contSubBox01{
        padding-bottom: 50px;
      }
    }
    .contBox03{
      .contSubBox01{
        padding-bottom: 50px;
      }
    }
    .contBox04{
      .contSubBox{
        .lowerTitle{
          line-height: 1.2;
          margin-bottom: 0;
        }
      }
    }
  }

  /*
  お知らせ（公式ブログ） 一覧・カテゴリ共通
  -------------------------------------*/
  #PageBlog.pageIndex,
  #PageBlog.pageCategory {
    .contBox01{
      padding: 18px 15px 80px;
      .contSubBox01{
        width: 100%;
        float: none;
        margin-bottom: 60px;
        .post{
          margin-bottom: 50px;
          &_item{
            &_cont{
              padding: 40px 0 30px;
              .thumb{
                width: 100%;
                float: none;
                margin-bottom: 27px;
              }
              .entry{
                width: 100%;
                float: none;
              }
            }
          }
        }
        .btnWrap{
          width: 100%;
        }
      }
    }
  }

  /*
  カテゴリ
  -------------------------------------*/
  #PageBlog.pageCategory {
  }

  /*
  記事詳細
  -------------------------------------*/
  #PageBlog.pageEntry {
    .contBox01{
      padding: 41px 15px 90px;
      .contSubBox01{
        width: 100%;
        float: none;
        margin-bottom: 20px;
        .postCont{
          margin-bottom: 34px;
          &_head{
            margin-bottom: 13px;
            .label{
              margin-bottom: 26px;
            }
          }
        }
        .btnWrap{
          width: 100%;
          padding-left: 0;
          .pgBtn{
            margin-bottom: 0;
            &.prev{
              a{
                padding-left: 12px;
              }
            }
            &.back{
              a{
                padding-right: 20px;
              }
            }
            &.next{
              a{
                padding-right: 12px;
                &::before{
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  見学予約・資料請求 共通
  -------------------------------------*/
  #PageContact {
    /*---メインイメージ---*/
    #MainImg{
      #MainImgInner{
        background: url(../img/contents/contact/main_visu_contact.png) no-repeat center center;
        background-size: cover;
        #MainTitle{
          font-size: 32px;
        }
        .mainSubTitle {
          margin-top: 10px;
        }
      }
    }
  }


  /*
  見学予約・資料請求　.pageIndex　.pageConfirm 共通
  -------------------------------------*/
  #PageContact.pageIndex,
  #PageContact.pageConfirm {
    .contBox01{
      padding: 50px 15px 45px;
      .contSubBox01{
        width: 100%;
        form{
          table{
            width: 100%;
            tr{
              th{
                padding: 24px 0 0;
                width: 100%;
                display: block;
              }
              td{
                padding: 14px 0 29px;
                display: block;
              }
              &#Purpose{
                th{
                  padding: 24px 0 0;
                }
                td{
                  padding: 13px 0 29px;
                }
              }
              &#Name,
              &#Hurigana{
                td{
                  input{
                    width: 100%;
                  }
                }
              }
              &#Tel{
                td{
                  input{
                    width: 100%;
                  }
                }
              }
              &#Email{
                td{
                  input{
                    width: 100%;
                  }
                }
              }
              &#Calendar{
                td{
                  &:nth-of-type(1){
                    width: 100%;
                    padding-bottom: 0;
                  }
                  &:nth-of-type(2){
                    padding-left: 0;
                  }
                  input{
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  見学予約・資料請求　.pageIndex
  -------------------------------------*/
  #PageContact.pageIndex {
    .contBox02{
      padding: 0 15px 35px;
      .contTitle{
        margin-bottom: 23px;
      }
    }
  }

  /*
  送信内容確認ページ（見学予約・資料請求）
  -------------------------------------*/
  #PageContact.pageConfirm {
    .contBox01{
      padding: 50px 15px 65px;
      .contSubBox01{
        form {
          table {
            tr {
              th{
                width: 100%;
                padding: 24px 0 0;
              }
              td{
                padding: 5px 0 22px;
              }
              &#Purpose{
                 td{
                  padding: 5px 0 21px;
                }
              }
              &#Calendar {
                td{
                  &:nth-of-type(1){
                    padding-bottom: 20px;
                  }
                }
              }
            }
          }
        }
        .btnWrap{
          position: static;
          .linkBtn{
            margin-bottom: 16px;
          }
          .back{
            position: static;
          }
        }
      }
    }
  }

  /*
  送信完了ページ（見学予約・資料請求）
  -------------------------------------*/
  #PageContact.pageThanks {
    .contBox01{
      padding: 50px 15px 80px;
      .lowerTitle{
        margin-bottom: 40px;
        line-height: 1.2;
      }
      .contSubBox01{
        .btnWrap{
          margin-top: 40px;
        }
      }
    }
  }

}


@media all and (max-width: 767px) and (-ms-high-contrast: none) {
    #PageAbout.pageIndex #MainImg #MainImgInner .video{
      height: auto;
    }
}



@media screen and (max-width: 505px) {
  #PageAbout.pageIndex .contBox01 .contSubBox04 .setRight .space {
    margin-top: 10%;
  }
}
@media screen and (max-width: 440px) {
  #PageAbout.pageIndex .contBox01 .contSubBox04 .setRight .space {
    margin-top: 23%;
  }
}
@media screen and (max-width: 390px) {
  #PageAbout.pageIndex .contBox01 .contSubBox04 .setRight .space {
    margin-top: 32%;
  }
}
@media screen and (max-width: 382px) {
  #PageAbout.pageIndex .contBox01 .contSubBox04 .setRight .space {
    margin-top: 34%;
  }
}
@media screen and (max-width: 374px) {
  #PageProcedure.pageIndex .contBox01 .contSubBox05 .boxWrap .rightBox .frameBtnGrn{
   // padding: 15px 25px 10px;
  }
  #PageAbout.pageIndex .contBox01 .contSubBox02 .pointCont .pointItem .infoBoxWrap .infoBox{
    padding: 20px 20px;
  }
  #PageAbout.pageIndex .contBox01 .contSubBox02 .pointCont .diagBoxWrap .diagBox dd span{
    font-size: 22px;
  }
}
@media screen and (max-width: 370px) {
  #PageAbout.pageIndex .contBox01 .contSubBox04 .setRight .space {
    margin-top: 51%;
  }

  //調整
  #Footer .footerInfo_list a{
    font-size: 13px;
  }

}

@media screen and (max-width: 355px) {
#PageAbout.pageIndex{
  .contBox01{
    .contSubBox02{
        .pointCont{
          .diagBoxWrap{
            .diagBox{
              padding-left: 40px;
              margin-top: 24px;
              &:nth-of-type(1), &:nth-of-type(2){
                margin-bottom: 6px;
              }
              &.train{
                background: url(../img/contents/about/icon_train02.png) no-repeat left 0 top 16px;
                background-size: 30px auto;
              }
              &.walk{
                background: url(../img/contents/about/icon_walk02.png) no-repeat left 7px top 4px;
                background-size: 28px auto;
                padding-left: 50px;
              }
              &.bus{
                background: url(../img/contents/about/icon_bus02.png) no-repeat left 0 top 13px;
                background-size: 30px auto;
              }
              &.car{
                background: url(../img/contents/about/icon_car02.png) no-repeat left 9px top 14px;
                background-size: 30px auto;
                padding-left: 40px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  #PageAbout.pageIndex .contBox01 .contSubBox04 .setRight .space {
    margin-top: 53%;
  }

  //調整
  #PageMihon.pageIndex .contBox03 .contSubBox01 .post .post_item .post_item_cont .boxWrapBottom .priceBox .price,
  #PageMihon.pageCategory .contBox03 .contSubBox01 .post .post_item .post_item_cont .boxWrapBottom .priceBox .price{
    font-size: 42px;
  }
  #PageMihon.pageIndex .contBox03 .contSubBox01 .post .post_item .post_item_cont .boxWrapBottom .priceBox .price span,
  #PageMihon.pageCategory .contBox03 .contSubBox01 .post .post_item .post_item_cont .boxWrapBottom .priceBox .price span{
    font-size: 20px;
  }
  #PageMihon.pageEntry .contBox01 .boxWrapBody01 .infoBoxTop .priceBox .price{
    font-size: 36px;
  }
  #PageMihon.pageEntry .contBox01 .boxWrapBody01 .infoBoxTop .priceBox .price .unit{
    font-size: 20px;
  }

  #PageEternal.pageIndex .contBox .contSubBox03 .panelBox .cardBox .flow dd span{
    font-size: 14px;
  }
  #PageGohouji #MainImg #MainImgInner{
    #MainTitle{
      font-size: 34px;
    }
    .mainSubTitle{
      font-size: 11px;
    }
  }
  #PageProcedure.pageIndex .contBox01 .contSubBox .linkBtn{
    font-size: 16px;
  }
  #PageFlow.pageIndex #MainImg #MainImgInner #MainTitle{
    font-size: 28px;
  }
  #PageBlog.pageEntry .contBox01 .contSubBox01 .btnWrap .pgBtn a{
    font-size: 14px;
  }
  #Footer .footerInfo_list a{
    font-size: 12px;
  }
}
@media screen and (max-width: 330px) {
  #PageAbout.pageIndex .contBox01 .contSubBox04 .setRight .space {
    margin-top: 60%;
  }
  #PageAbout.pageIndex .contBox01 .contSubBox02 .pointCont .pointItem .infoBoxWrap .infoBox{
    padding: 20px 15px;
  }
}
@media screen and (max-width: 320px) {
  #PageAbout.pageIndex .contBox01 .contSubBox04 .setRight .space {
    margin-top: 70%;
  }

  //調整
  #Page.pageIndex #ContBox02 #ContSubBox02 .frameBtnOrg{
    font-size: 16px;
  }
  #Page.pageIndex #ContBox03 .h_title{
    font-size: 28px;
  }
  #Page.pageIndex #ContBox03 #ContSubBox03 .modal_title{
    font-size: 12px;
    background-size: 18px auto;
  }
  #Page.pageIndex #ContBox03 #ContSubBox03 .modal_list:nth-of-type(odd) .modal_title{
    background-position: right 10px center;
  }
  #Page.pageIndex #ContBox04 .h_title{
    font-size: 25px;
  }
  #Footer .footerInfo_list a{
    font-size: 11px;
  }

}
@media screen and (max-width: 310px) {
  #PageAbout.pageIndex .contBox01 .contSubBox04 .setRight .space {
    margin-top: 90%;
  }
}